import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.button`
  border: none;
  background: none;
  
  font-weight: bold;
  height: 30px;
  color: ${props => props.active ? props.theme.colors.green : props.theme.colors.turquoise};
  
  ${props => props.theme.fontSize(20)}
`;

const Toggle = styled.button`
  width: 60px;
  height: 30px;
  border-radius: 15px;
  background-color: ${props => props.theme.colors.grey};
  border: none;
  position: relative;
`;

const ToggleIcon = styled.span`
  position: absolute;
  height: 22px;
  width: 22px;
  border-radius: 22px;
  background-color: #3CBF0A;
  left: ${props => props.left ? "5px" : "35px"};
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 2px 6px 0 rgba(118,118,118,0.5);
  transition: left 300ms;
`;

const ToggleState = ({ selected, options: [left, right], onSelect }) => (
  <Wrapper>
    <Label onClick={() => onSelect(left)} active={selected === left}>
      {left}
    </Label>
    <Toggle onClick={() => onSelect(selected === left ? right : left)}>
      <ToggleIcon left={selected === left} right={selected === right}>
        <span className="sr-only">
          Toggle
        </span>
      </ToggleIcon>
    </Toggle>
    <Label onClick={() => onSelect(right)} active={selected === right}>
      {right}
    </Label>
  </Wrapper>
);

ToggleState.propTypes = {
  selected: PropTypes.number.isRequired,
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default ToggleState;
