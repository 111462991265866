import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { useOnScreen } from "../../hooks/useOnScreen";

const Wrapper = styled.div`
  opacity: ${props => props.visible ? 1 : 0};
  transition: opacity ${props => props.theme.animations.fade.duration}ms;
`;

const FadeIn = ({ disable, children }) => {
  const [ref, isOnScreen] = useOnScreen(0.1);
  const visible = disable ? true : isOnScreen;

  return (
    <Wrapper ref={ref} visible={visible}>
      {children}
    </Wrapper>
  );
};

FadeIn.propTypes = {
  disable: PropTypes.bool,
  children: PropTypes.node
};

FadeIn.defaultProps = {
  disable: false,
};

export default FadeIn;
