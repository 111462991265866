import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  color: ${props => props.theme.colors[props.colorName]};
  margin-top: 40px;
`;

const Title = styled.h4`
  ${props => props.theme.fontSize(20)}
`;

const ListWrapper = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
  ${props => props.theme.fontSize(20)}
`;

const ListItem = styled.li`
  position: relative;
  padding-left: 60px;
  margin: 10px 0;
`;

const ListPercentage = styled.span`
  position: absolute;
  left: 0;
  font-weight: bold;
`;

const ListText = styled.span`
  color: ${props => props.theme.textColor};
`;

const List = ({ title, data, color }) => {

  return (
    <Wrapper colorName={color}>
      <Title>{title}</Title>

      <ListWrapper>
        {data.map(({ value, text }, index) => (
          <ListItem key={index}>
            <ListPercentage>{value}%</ListPercentage>
            <ListText>&quot;{text}&quot;</ListText>
          </ListItem>
        ))}
      </ListWrapper>

    </Wrapper>
  );
};

List.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired
};

export default List;
