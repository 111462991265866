import PropTypes from "prop-types";
import React from "react";
import styled, { keyframes } from "styled-components";
import { useOnScreen } from "../../hooks/useOnScreen";

const grow = keyframes`
  0% {transform: scale(0) translate(0, 0);}
  100% {transform: scale(1) translate(0, 0);}
`;

const shrink = keyframes`
  0% {transform: scale(1) translate(0, 0);}
  100% {transform: scale(0) translate(0, 0);}
`;

const moveDown = keyframes`
  0% {transform: translate(0, 0);}
  100% {transform: translate(0, 25px);}
`;

const Path = styled.path`
  animation: ${props => props.animation} 500ms infinite linear;
  animation-play-state: ${props => props.isOnScreen ? "play" : "paused"};
  transform-origin: ${props => props.origin};
`;

const ArrowsDown = ({ fill, width }) => {
  const [ref, isOnScreen] = useOnScreen(0.6);

  return (
    <svg ref={ref} viewBox="0 0 18 64.5" xmlns="http://www.w3.org/2000/svg" fill={fill} width={width}>
      <g>
        <Path d="M0,0 16.74,0 8.37,14.5z" isOnScreen={isOnScreen} animation={grow} origin="8.37px 0"/>
      </g>
      <g>
        <Path d="M0,0 16.74,0 8.37,14.5z" isOnScreen={isOnScreen} animation={moveDown} origin="8.37px 0"/>
      </g>
      <g transform="translate(0 25)">
        <Path d="M0,0 16.74,0 8.37,14.5z" isOnScreen={isOnScreen} animation={moveDown} origin="8.37px 0"/>
      </g>
      <g transform="translate(0 50)">
        <Path d="M0,0 16.74,0 8.37,14.5z" isOnScreen={isOnScreen} animation={shrink} origin="8.37px 14.5px"/>
      </g>
    </svg>
  );
};

ArrowsDown.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number
};

ArrowsDown.defaultProps = {
  fill: "#A94A7D",
  width: 20
};

export default ArrowsDown;
