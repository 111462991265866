import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const FULL_WIDTH_VALUE = 45

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 75px 1fr;
  padding: 20px 0;

  ${props => props.theme.tablet`
     grid-template-columns: 1fr 132px 1fr;
  `}
`

const BarWrapper = styled.div`
  height: 50px;
  position: relative;
`

const Bar = styled.div`
  color: #fff;
  background-color: ${props => props.theme.colors.pink};
  line-height: 50px;
  text-align: ${props => (props.left ? "right" : "left")};
  position: absolute;
  top: 0;
  ${props => props.left && "right: 0;"}
  ${props => props.right && "left: 0;"}
  width: ${props => (props.visible ? props.percent : 0)}%;
  font-weight: bold;
  transition: width ${props => props.theme.animations.duration}ms;
  font-size: 16px;

  span {
    padding: 0 5px;
    opacity: ${props => (props.visible ? 1 : 0)};
    transition: opacity ${props => props.theme.animations.duration}ms;
    font-variant-numeric: tabular-nums;
    font-size: 14px;

    ${props => props.theme.tablet`
      font-size: 16px;
      padding: 0 10px;
    `}
  }
`

const Label = styled.div`
  height: 100%;
  text-transform: capitalize;
  font-weight: bold;
  text-align: center;
  background-color: ${props => props.theme.colors.grey};
  color: ${props => props.theme.colors.purple};

  font-size: 16px;
  line-height: 50px;

  ${props => props.theme.tablet`
    font-size: 18px;
  `}
`

const MirrorBar = ({ label, data, onScreen }) => {
  const { happy, unhappy } = data

  return (
    <Wrapper>
      <BarWrapper>
        <Bar
          left
          percent={(unhappy / FULL_WIDTH_VALUE) * 100}
          visible={onScreen}
        >
          <span>{unhappy}%</span>
        </Bar>
      </BarWrapper>

      <Label>{label}</Label>

      <BarWrapper>
        <Bar
          right
          percent={(happy / FULL_WIDTH_VALUE) * 100}
          visible={onScreen}
        >
          <span>{happy}%</span>
        </Bar>
      </BarWrapper>
    </Wrapper>
  )
}

MirrorBar.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.shape({
    happy: PropTypes.number.isRequired,
    unhappy: PropTypes.number.isRequired,
  }),
}

export default MirrorBar
