import React from "react";
import styled, { keyframes } from "styled-components";
import { useOnScreen } from "../../hooks/useOnScreen";
import Path from "../PipeTrack/Path";

const HEIGHT = 300;

const fadeDown = keyframes`
    0% {transform: scale(0) translate(0, -20px); opacity: 0;}
    20% {transform: scale(1) translate(0, -20px); opacity: 1;}
    100% {transform: translate(0, 20px); opacity: 0;}
`;

const Wrapper = styled.div`
  height: ${HEIGHT}px;
  position: relative;
`;

const BoxWrapper = styled.div`
  width: 200px;
  height: 110px;
  margin: auto;
  background-color: ${props => props.theme.colors.grey};
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 30px;
  transform: translateY(${HEIGHT - 110}px);
`;

const TearGroup = styled.g`
  transition: opacity 300ms;
  opacity: ${props => props.isOnScreen ? 0 : 1};
`;

const TearDrop = styled.path`
  animation: infinite ${fadeDown} 1s linear;
  transform-origin: 15px 0;
  animation-delay: ${props => props.delay}ms;
`;

const BoxTeardrops = () => {
  const [ref, isOnScreen] = useOnScreen(0.6, false);

  return (
    <Wrapper ref={ref}>
      <BoxWrapper>
        <svg width="142" height="51" style={{ overflow: "visible" }}>
          <g>
            <TearDrop fill="#40B0B0" delay={100}
                      d="M4.9594718 45.96c-5.409-5.409-6.52-13.773-2.708-20.406L16.9324718 0l14.68 25.554c3.812 6.633 2.701 14.997-2.708 20.406-6.612 6.612-17.333 6.612-23.945 0"/>
          </g>
          <TearGroup transform="translate(55 0)" isOnScreen={isOnScreen}>
            <TearDrop fill="#A94A7D" delay={400}
                      d="M4.9594718 45.96c-5.409-5.409-6.52-13.773-2.708-20.406L16.9324718 0l14.68 25.554c3.812 6.633 2.701 14.997-2.708 20.406-6.612 6.612-17.333 6.612-23.945 0"/>
          </TearGroup>
          <g transform="translate(108 0)">
            <TearDrop fill="#40B0B0" delay={250}
                      d="M4.9594718 45.96c-5.409-5.409-6.52-13.773-2.708-20.406L16.9324718 0l14.68 25.554c3.812 6.633 2.701 14.997-2.708 20.406-6.612 6.612-17.333 6.612-23.945 0"/>
          </g>
        </svg>
      </BoxWrapper>

      <Path height={HEIGHT} d={`M0,0 0,${HEIGHT}`} center/>
    </Wrapper>
  );
};

export default BoxTeardrops;
