import styled from "styled-components";

const Button = styled.button`
  display: inline-block;
  color: #ffffff;
  background-color: ${props => props.theme.colors.green};
  border: none;
  font-weight: bold;
  cursor: pointer;
  padding: 5px 30px;
  transition: background-color 500ms;
  margin: 10px;
  
  ${props => props.theme.fontSize(20)}
  
  &:hover {
    background-color: ${props => props.theme.colors.darkGreen};
    border: none;
    padding: 5px 30px;
    text-decoration: none;
    color: #ffffff;
  }
  
  ${props => props.theme.tablet`
    margin: 0 5px;
  `}
`;

export default Button;
