import React from "react";
import styled from "styled-components";

const List = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  position: absolute;
  letter-spacing: -0.36px;
  font-weight: bold;
  left: 50%;
  
  width: 110px;
  font-size: 16px;
  line-height: 19px;
  
  ${props => props.theme.tablet`
    font-size: 24px;
    line-height: 36px;
    width: 150px;
  `}
`;

const ListItemOne = styled(ListItem)`
  top: 165px;
  transform: translateX(-50%);
  
  ${props => props.theme.tablet`
    top: 335px;
  `}
`;

const ListItemTwo = styled(ListItem)`
  top: 235px;
  transform: translateX(35px);
  
  ${props => props.theme.tablet`
    top: 470px;
    transform: translateX(100px);
  `}
`;

const ListItemThree = styled(ListItem)`
  top: 305px;
  transform: translateX(15px);
  
  ${props => props.theme.tablet`
    top: 610px;
    transform: translateX(35px);
    width: 200px;
  `}
`;

const ListItemFour = styled(ListItem)`
  top: 305px;
  transform: translateX(-125px);
  
  ${props => props.theme.tablet`
    top: 610px;
    transform: translateX(-215px);
  `}
`;

const ListItemFive = styled(ListItem)`
  top: 235px;
  transform: translateX(-145px);
  
  ${props => props.theme.tablet`
    top: 475px;
    transform: translateX(-285px);
    width: 200px;
  `}
`;

const Contents = () => (
  <List>
    <ListItemOne>
      Don't ask, don't get
    </ListItemOne>
    <ListItemTwo>
      The cost of unequal pay
    </ListItemTwo>
    <ListItemThree>
      The awareness&nbsp;gap
    </ListItemThree>
    <ListItemFour>
      Sad resignation
    </ListItemFour>
    <ListItemFive>
      The secret bias&nbsp;of&nbsp;job&nbsp;ads
    </ListItemFive>
  </List>
);

export default Contents;
