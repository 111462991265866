import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import ChartTitle from "../ChartTitle";
import FadeIn from "../FadeIn";
import List from "./List";

const Wrapper = styled.div`
  padding-bottom: 40px;
`;

const ListChart = ({ config }) => {
  const { title, men, women } = config;

  return (
    <FadeIn>
      <Wrapper>
        <ChartTitle>{title}</ChartTitle>

        <List title="Women" data={women} color={"women"}/>
        <List title="Men" data={men} color={"men"}/>

      </Wrapper>
    </FadeIn>
  );
};

ListChart.propTypes = {
  config: PropTypes.object.isRequired
};

export default ListChart;
