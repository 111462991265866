import styled from "styled-components";

const BackgroundHighlight = styled.div`
    background-color: ${props => props.theme.colors.grey};
    padding: 50px 0;
    z-index: 3;
    position: relative;
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
`;

export default BackgroundHighlight;
