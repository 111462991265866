import now from "performance-now";
import raf from "raf";
import React, { useEffect, useRef } from "react";
import { withSize } from "react-sizeme";
import styled from "styled-components";
import Dot from "../Dot";
import ArrowRightSVG from "../../images/conclusion/arrows-right.svg";
import ArrowLeftSVG from "../../images/conclusion/arrows-left.svg";

const PATH_HEIGHT = 35;
const CORNER = PATH_HEIGHT * 1.5;
const FRAME_DURATION = 10000 / 60;

const Header = styled.div`
  position: relative;
  padding: 70px;
  margin: 50px 0;
`;

const SVG = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
`;

const Path = styled.path`
    stroke: ${props => props.theme.colors.grey};
    stroke-width: 35px;
    fill: none;
`;

const Title = styled.h1`
  text-align: center;
  max-width: 420px;
  margin: auto;
  
  ${props => props.theme.fontSize(34)}
`;

const DotWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
`;

const ArrowRight = styled.div`
  width: 98px;
  height: 20px;
  background: url(${ArrowRightSVG}) no-repeat center center;
  position: absolute;
  top: 8px;
  left: 70px;
`;

const ArrowLeft = styled.div`
  width: 98px;
  height: 20px;
  background: url(${ArrowLeftSVG}) no-repeat center center;
  position: absolute;
  bottom: 8px;
  right: 60px;
`;

const ConclusionHeader = ({ size }) => {
  const dotRef = useRef();
  const pathRef = useRef();
  const { height, width } = size;

  useEffect(() => {
    const pathElement = pathRef.current;
    const currentDot = dotRef.current;
    const length = pathElement.getTotalLength();
    const { width, height } = currentDot.getBoundingClientRect();
    let animating = true;

    let positionRafDelta = 0;
    let lastRafUpdate = now();

    const animate = () => {
      const timeNow = now();
      const delta = (timeNow - lastRafUpdate) / FRAME_DURATION;

      positionRafDelta += delta;

      if (positionRafDelta > 100) {
        positionRafDelta = positionRafDelta - 100;
      }

      const { x, y } = pathElement.getPointAtLength(length * (positionRafDelta / 100));

      currentDot.style.left = `${x - (width / 2)}px`;
      currentDot.style.top = `${y - (height / 2)}px`;

      lastRafUpdate = timeNow;

      if (animating) {
        raf(animate);
      }
    };

    animate();

    return () => animating = false;


  }, [height, width]);

  return (
    <Header>
      <Title>
        Escaping the Gender Pay Trap isn’t that simple
      </Title>

      <DotWrap ref={dotRef}>
        <Dot/>
      </DotWrap>

      <SVG>
        <Path
          ref={pathRef}
          d={`
            M${width / 2},${PATH_HEIGHT / 2} ${width - CORNER},${PATH_HEIGHT / 2} ${width - CORNER},${CORNER} ${width - (PATH_HEIGHT / 2)},${CORNER} 
            ${width - (PATH_HEIGHT / 2)},${height - CORNER} ${width - CORNER},${height - CORNER} ${width - CORNER},${height - (PATH_HEIGHT / 2)} 
            ${CORNER},${height - (PATH_HEIGHT / 2)} ${CORNER},${height - CORNER} ${PATH_HEIGHT / 2},${height - CORNER} 
            ${PATH_HEIGHT / 2},${CORNER} ${CORNER},${CORNER} ${CORNER},${PATH_HEIGHT / 2} ${width / 2},${PATH_HEIGHT / 2}
        `}/>
      </SVG>

      <ArrowRight/>
      <ArrowLeft/>

    </Header>
  );
};

export default withSize({ monitorHeight: true })(ConclusionHeader);
