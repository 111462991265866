import React from "react";
import AnimateHeight from "react-animate-height";
import { Element } from "react-scroll";
import styled from "styled-components";
import BodyText from "../BodyText";
import ConclusionHeader from "../ConclusionHeader";
import ConclusionHowTo from "../ConclusionHowTo";
import CentreColumn from "../Grid/CentreColumn";

const Wrapper = styled.div`
  position: relative;
  padding-top: 100px;
  overflow: hidden;
`;

const Conclusion = ({ showConclusion }) => {
  const height = showConclusion ? "auto" : 0;

  return (
    <AnimateHeight height={height} duration={1000}>
      <Wrapper showConclusion={showConclusion}>
        <CentreColumn withoutPath>
          <Element id="conclusion"/>

          <ConclusionHeader/>

          <BodyText noFade>
            Workplace equality is hard to achieve while we remain trapped in a vicious cycle, where every stage
            reinforces
            the next.
          </BodyText>
          <BodyText noFade>
            But together, we can begin to break out. Everyone can do something, starting now.
          </BodyText>

          <ConclusionHowTo/>
        </CentreColumn>
      </Wrapper>
    </AnimateHeight>
  );
};

export default Conclusion;
