import React from "react";
import styled from "styled-components";
import BackgroundHighlight from "../../BackgroundHighlight";
import Link from "../../Link";

const Wrapper = styled.div`
  max-width: 100%;
  margin: auto;
  padding: 40px 10px;
  
  ${props => props.theme.tablet`
    max-width: ${props.theme.breakpoints.tablet.width}px;
  `}
  
  ${props => props.theme.desktop`
    max-width: ${props.theme.breakpoints.desktop.width}px;
  `}
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;
  letter-spacing: -0.71px;
  
  ${props => props.theme.fontSize(34)}
`;

const LinkContainer = styled.div`
  ${props => props.theme.tablet`
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-gap: 20px;
  `}
  
  ${props => props.theme.desktop`
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  `}
`;

const LinkWrapper = styled.div`
  padding: 60px 0 0;
  
  a {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.5px;
    font-weight: bold;
  }
  
  p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.26px;
    padding-top: 20px;
  }
`;

const Explore = () => (
  <BackgroundHighlight>
    <Wrapper>
      <Title>
        Keep exploring your work-self...
      </Title>

      <LinkContainer>

        <LinkWrapper>
          <Link href="https://www.totaljobs.com/salary-checker/salary-calculator">
            The Totaljobs salary checker
          </Link>
          <p>
            Use our calculator to compare average salaries for any job or industry within any location in the UK.
          </p>
        </LinkWrapper>

        <LinkWrapper>
          <Link href="https://www.totaljobs.com/advice/how-to-ask-for-a-pay-rise">
            How to ask for a pay rise
          </Link>
          <p>
            The complete guide and all the tips you need for negotiating a salary increase or pay raise.
          </p>
        </LinkWrapper>

        <LinkWrapper>
          <Link href="https://www.totaljobs.com/advice/how-to-resign">
            How to resign
          </Link>
          <p>
            Thinking of quitting your job, but not sure how to proceed? From the right etiquette to the legal details,
            we tell you how to hand in your notice.
          </p>
        </LinkWrapper>

        <LinkWrapper>
          <Link href="https://www.totaljobs.com/insidejob/gender-bias-decoder">
            The Gender Bias Decoder
          </Link>
          <p>
            Check your text for gender-biased language, to help you write unbiased job adverts.
          </p>
        </LinkWrapper>

      </LinkContainer>
    </Wrapper>
  </BackgroundHighlight>
);

export default Explore;
