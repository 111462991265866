import React from "react"
import CountUp from "react-countup"
import styled from "styled-components"

const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
`

const Wrapper = styled.div`
  position: absolute;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px dashed #a94a7d;
  color: ${props => props.theme.colors.pink};
  text-align: center;
  width: 100%;
  font-variant-numeric: tabular-nums;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    white-space: nowrap;
    background-color: ${props => props.theme.colors.grey};
    padding: 0 5px;
    font-size: 16px;

    ${props => props.theme.tablet`
        font-size: 18px;
    `}
  }
`

const Difference = ({ data, prefix, suffix, onScreen }) => {
  const [lower, upper] = data

  const diff = upper.value - lower.value

  return (
    <Container visible={onScreen}>
      <Wrapper>
        <CountUp
          end={onScreen ? diff : 0}
          prefix={prefix}
          suffix={`${suffix} gap`}
          separator=","
          decimals={0}
          preserveValue
        />
      </Wrapper>
    </Container>
  )
}

export default Difference
