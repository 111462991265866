import React from "react";
import styled, { keyframes } from "styled-components";

const FLASHING_TIME = 1000;

const Flashing = keyframes`
  0% {opacity: 1;}
  50% {opacity: 0;}
  100% {opacity: 1;}
`;

const Text = styled.p`
  color: #76767C;
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.19px;
  
  animation: linear ${FLASHING_TIME}ms ${Flashing} 3;
`;

const StartText = ({ children }) => (
  <Text>{children}</Text>
);

export default StartText;
