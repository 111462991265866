import PropTypes from "prop-types";
import React from "react";
import { scroller } from "react-scroll";
import styled from "styled-components";
import CircleSVG from "../../images/shapes/circle.inline.svg";
import DiamondSVG from "../../images/shapes/diamond.inline.svg";
import RectangleFlipSVG from "../../images/shapes/rectangle-flip.inline.svg";
import RectangleSVG from "../../images/shapes/rectangle.inline.svg";
import { getColumnWidth } from "../../utils/theme";
import Button from "../Button";
import FadeIn from "../FadeIn";
import Shorter from "./Shorter";

const Wrapper = styled.div`
    width: 100%;
    margin: 0 auto 5px;
    text-align: center;
    z-index: 4;
    position: relative;
    
    ${props => props.theme.tablet`
      max-width: ${props => getColumnWidth(props.theme.breakpoints.tablet, 6)}px;
    `}
    
    ${props => props.theme.desktop`
      max-width: ${props => getColumnWidth(props.theme.breakpoints.desktop, 8)}px;
    `}
`;

const Content = styled.div`
    width: 100%;
    background-color: ${props => props.theme.colors.grey};
    padding: 0 10px;
`;

const Title = styled.h2`
    margin: 0 0 20px;
    
  ${props => props.theme.fontSize(34)}
`;

const Text = styled.p`
    font-size: 24px;
    line-height: 34px;
    margin: 0;
    ${({ bottom }) => bottom && `margin-bottom: 40px;`}
`;

const Buttons = styled.div`
    padding-bottom: 10px;
`;

const Congratulations = ({ final, onRestart, onEscape }) => {
  const onClick = () => {
    if (final) {
      onRestart();
    }

    scroller.scrollTo("stage-1", {
      duration: 1000,
      smooth: "easeInOutQuart"
    });
  };

  return (
    <Wrapper>
      <Shorter bottomLeft={<DiamondSVG fill="#7FCACA"/>} bottomRight={<RectangleSVG fill="#A94A7D"/>}/>
      <Content>
        <FadeIn>
          <Title>Congratulations!</Title>

          <Text>You’ve been offered a new job.</Text>
          <Text bottom>You're asked for your salary expectations.</Text>

          <Buttons>
            <Button onClick={onClick}>
              Let’s talk salaries
            </Button>

            {final && (
              <Button onClick={onEscape}>
                Escape the trap!
              </Button>
            )}
          </Buttons>
        </FadeIn>
      </Content>
      <Shorter topLeft={<RectangleFlipSVG fill="#F49164"/>} topRight={<CircleSVG fill="#40B0B0"/>}/>
    </Wrapper>
  );
};

Congratulations.propTypes = {
  final: PropTypes.bool,
  onRestart: PropTypes.func,
  onEscape: PropTypes.func
};

Congratulations.defaultProps = {
  final: false,
  onRestart: () => {
  }
};

export default Congratulations;
