import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { useOnScreen } from "../../hooks/useOnScreen"
import ArrowDownDesktopSVG from "../../images/mirror-bar/downDesktop.svg"
import ArrowDownTabletSVG from "../../images/mirror-bar/downTablet.svg"
import ArrowDownMobileSVG from "../../images/mirror-bar/downMobile.svg"
import ArrowLeftSVG from "../../images/mirror-bar/left.inline.svg"
import ArrowRightSVG from "../../images/mirror-bar/right.inline.svg"
import ChartTitle from "../ChartTitle"
import FadeIn from "../FadeIn"
import MirrorBar from "./MirrorBar"

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 50px;
`

const Labels = styled.div`
  position: relative;
  height: 150px;
  margin-top: 50px;
  color: #5F5F60;

  ${props => props.theme.fontSize(18)}

  ${props => props.theme.tablet`
    height: 70px;
  `}

  ${props => props.theme.desktop`
    height: 70px;
  `}
`

const MiddleLabel = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;

  ${props => props.theme.desktop`
    white-space: normal;
    width: 200px;
    text-align: center;
  `}
`

const ArrowDown = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
  width: 11px;
  height: 103px;
  background: url(${ArrowDownMobileSVG});

  ${props => props.theme.tablet`
    background: url(${ArrowDownTabletSVG});
    height: 64px;
    top: 20px;
  `}

  ${props => props.theme.desktop`
    background: url(${ArrowDownDesktopSVG});
    height: 34px;
    top: 40px;
  `}
`

const UnhappyLabel = styled.div`
  position: absolute;
  top: 80px;
  left: 20px;
  width: 100px;

  ${props => props.theme.tablet`
    width: auto;
    top: 40px;
  `}

  ${props => props.theme.desktop`
    width: auto;
    top: 30px;
  `}

  svg {
    position: absolute;
    left: -25px;
    top: 14px;
  }
`

const HappyLabel = styled.div`
  position: absolute;
  top: 80px;
  right: 20px;
  width: 100px;

  ${props => props.theme.tablet`
    width: auto;
    top: 40px;
  `}

  ${props => props.theme.desktop`
    width: auto;
    top: 30px;
  `}

  svg {
    position: absolute;
    right: -25px;
    top: 14px;
  }
`

const MirrorBarChart = ({ config }) => {
  const [ref, onScreen] = useOnScreen()
  const { title, data, label, plural } = config
  const { fair, unfair } = data

  const text = plural
    ? {
        fair: "Fairly",
        unfair: "Unfairly",
      }
    : {
        fair: "fair",
        unfair: "unfair",
      }

  return (
    <FadeIn>
      <Wrapper ref={ref}>
        <ChartTitle>{title}</ChartTitle>

        <Labels>
          <UnhappyLabel>
            I'm&nbsp;unhappy with my job
            <ArrowLeftSVG />
          </UnhappyLabel>
          <MiddleLabel>
            {label}
            <ArrowDown />
          </MiddleLabel>
          <HappyLabel>
            I'm happy with my job
            <ArrowRightSVG />
          </HappyLabel>
        </Labels>

        <MirrorBar label={text.fair} data={fair} onScreen={onScreen} />
        <MirrorBar label={text.unfair} data={unfair} onScreen={onScreen} />
      </Wrapper>
    </FadeIn>
  )
}

MirrorBarChart.propTypes = {
  config: PropTypes.object.isRequired,
}

export default MirrorBarChart
