import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled, { useTheme } from "styled-components"

const Wrapper = styled.div`
  padding: 10px 0 30px;

  ${props => props.theme.tablet`
    padding: 10px 0;
  `}
`

const Label = styled.div`
  font-weight: bold;
  ${props => props.theme.fontSize(20)}
`

const BarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
`

const Bar = styled.div`
  display: inline-block;
  width: ${props => (props.visible ? props.percent : 0)}%;
  background-color: ${props => props.theme.colors[props.bgColor]};
  font-weight: bold;
  line-height: 30px;
  transition: width ${props => props.theme.animations.duration}ms;
  position: relative;

  span {
    color: ${props => props.theme.textColor};
    padding: 0 2px;
    transition: opacity ${props => props.theme.animations.duration}ms;
    opacity: ${props => (props.visible ? 1 : 0)};
    font-size: 14px;
    letter-spacing: -0.29px;

    ${props => props.theme.tablet`
      padding: 0 ${props => (props.percent > 7 ? 10 : 1)}px;
      color: ${props =>
        props.bgColor === "pink" ? "#fff" : props.theme.textColor};
    `}
  }
`

const Number = styled.span`
    position: absolute;
    top: 100%;

    ${props =>
      props.left &&
      `
      left: 0;
    `}

    ${props =>
      props.right &&
      `
      right: 0;
    `}

    ${props =>
      props.center &&
      `
      left: 50%;
      transform: translateX(-50%);
    `}

    ${props => props.theme.tablet`
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      transform: none;
    `}

`

const StackedBar = ({ data, onScreen }) => {
  const [animating, setAnimating] = useState(0)
  const { label, male, female, unbiased } = data
  const { animations } = useTheme()

  const total = male + female + unbiased

  let malePercent = (male / total) * 100
  let femalePercent = (female / total) * 100
  let unbiasedPercent = (unbiased / total) * 100

  useEffect(() => {
    if (!onScreen) {
      setAnimating(0)

      return
    }

    // just end if we get to 3
    if (animating >= 3) {
      return
    }

    // on the first one we want it to be instant
    if (animating === 0) {
      setAnimating(animating + 1)

      return
    }

    const id = setTimeout(() => {
      setAnimating(animating + 1)
    }, animations.duration)

    return () => clearTimeout(id)
  }, [onScreen, animating, animations])

  return (
    <Wrapper>
      <Label>{label}</Label>
      <BarWrapper>
        <Bar percent={malePercent} bgColor="men" visible={animating > 0}>
          <Number left>{male}%</Number>
        </Bar>
        <Bar percent={femalePercent} bgColor="women" visible={animating > 1}>
          <Number center>{female}%</Number>
        </Bar>
        <Bar percent={unbiasedPercent} bgColor="pink" visible={animating > 2}>
          <Number right>{unbiased}%</Number>
        </Bar>
      </BarWrapper>
    </Wrapper>
  )
}

StackedBar.propTypes = {
  data: PropTypes.object.isRequired,
}

export default StackedBar
