import React from "react"
import styled from "styled-components"
import { useOnScreen } from "../../hooks/useOnScreen"

const Wrapper = styled.div`
  padding-left: 120px;
  position: relative;
`

const SVG = styled.svg`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
`

const Group = styled.g`
  transform: translateX(${props => props.translate}px);
  transition: transform 2000ms;
  transform-origin: 46px 46px;
`

const Decode = () => {
  const [ref, isOnScreen] = useOnScreen(0.5, false)

  return (
    <Wrapper ref={ref}>
      <strong>Decode</strong>
      <p>
        Employers and recruiters, use the{" "}
        <a
          href="https://www.totaljobs.com/insidejob/gender-bias-decoder/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Gender Bias Decoder
        </a>{" "}
        to write unbiased job ads and attract more diverse talent.
      </p>

      <SVG width={92} height={92}>
        <rect fill="#F5F5F5" x="0" y="0" width="92" height="92" />

        <Group translate={isOnScreen ? -100 : 0}>
          <rect fill="#F49164" x="0" y="19" width="37" height="12" />
          <rect fill="#A94A7D" x="45" y="19" width="21" height="12" />
          <rect fill="#40B0B0" x="75" y="19" width="17" height="12" />
          <rect fill="#F49164" x="100" y="19" width="37" height="12" />
          <rect fill="#A94A7D" x="145" y="19" width="21" height="12" />
          <rect fill="#40B0B0" x="175" y="19" width="17" height="12" />
        </Group>

        <Group translate={isOnScreen ? 100 : 0}>
          <rect fill="#A94A7D" x="0" y="42" width="21" height="12" />
          <rect fill="#F49164" x="30" y="42" width="62" height="12" />
          <rect fill="#A94A7D" x="-100" y="42" width="21" height="12" />
          <rect fill="#F49164" x="-70" y="42" width="62" height="12" />
        </Group>

        <Group translate={isOnScreen ? -100 : 0}>
          <rect fill="#40B0B0" x="55" y="65" width="37" height="12" />
          <rect fill="#F49164" x="0" y="65" width="50" height="12" />
          <rect fill="#40B0B0" x="155" y="65" width="37" height="12" />
          <rect fill="#F49164" x="100" y="65" width="50" height="12" />
        </Group>
      </SVG>
    </Wrapper>
  )
}

export default Decode
