import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 30px 0;
  border-top: 1px solid #cccccf;
`;

const Nav = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const NavItem = styled.li`
  padding: 10px 20px 0 0;
  
  a {
   color: #3CBF0A;
   
   &:hover {
     text-decoration: none;
   }
  }
`;

const Copyright = styled.p`
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.25px;
  margin-top: 50px;
`;

const FooterNav = () => (
  <Wrapper>
    <Nav>
      <NavItem>
        <a href="https://www.totaljobs.com/about-us" rel="noopener noreferrer">
          About us
        </a>
      </NavItem>
      <NavItem>
        <a href="https://www.totaljobs.com/contact-us" rel="noopener noreferrer">
          Contact us
        </a>
      </NavItem>
      <NavItem>
        <a href="https://www.totaljobs.com/privacy-policy" rel="noopener noreferrer">
          Privacy Policy
        </a>
      </NavItem>
      <NavItem>
        <a href="https://www.totaljobs.com/terms-and-conditions" rel="noopener noreferrer">
          Terms and conditions
        </a>
      </NavItem>
      <NavItem>
        <a href="https://www.totaljobs.com/cookies" rel="noopener noreferrer">
          Cookies
        </a>
      </NavItem>
    </Nav>

    <Copyright>
      &copy; Copyright and database rights Totaljobs Group Ltd 2020
    </Copyright>

  </Wrapper>
);

export default FooterNav;
