import PropTypes from "prop-types";
import React from "react";
import { withSize } from "react-sizeme";
import styled from "styled-components";
import { getColumnWidth } from "../../utils/theme";
import Path from "./Path";

const HEIGHT = 100;

const Wrapper = styled.div`
  position: relative;
  max-width: ${props => getColumnWidth(props.theme.breakpoints.desktop, 10)}px;
  margin: auto;
  height: ${HEIGHT}px;
`;

const HalfPipe = ({ reverse, final, size }) => {
  const width = (size.width / 2) - Path.padding;
  const finalHeight = final ? HEIGHT + 20 : HEIGHT;

  let d = `M0,0 L0,${HEIGHT / 2} L${width},${HEIGHT / 2} L${width},${finalHeight}`;

  if (reverse) {
    d = `M${width},0 L${width},${HEIGHT / 2} L0,${HEIGHT / 2} L0,${finalHeight}`;
  }

  return (
    <Wrapper>
      <Path
        d={d}
        height={HEIGHT}
      />
    </Wrapper>
  );
};

HalfPipe.propTypes = {
  reverse: PropTypes.bool,
  final: PropTypes.bool
};

HalfPipe.defaultProps = {
  reverse: false,
  final: false
};


export default withSize()(HalfPipe);
