import React from "react";
import styled from "styled-components";
import { useOnScreen } from "../../../../hooks/useOnScreen";

const Wrapper = styled.div`
  letter-spacing: -0.41px;
  position: relative;
  
  ${props => props.theme.fontSize(20)}
`;

const Icon = styled.div`
  position: absolute;
  top: 0;
  left: -56px;
  transform: rotate(${props => props.isOnScreen ? "-90deg" : 0});
  transition: transform 500ms;
`;

const ListItem = ({ item, icon }) => {
  const [ref, isOnScreen] = useOnScreen(0.6, false);

  return (
    <Wrapper ref={ref}>
      {item}

      <Icon isOnScreen={isOnScreen}>
        {icon}
      </Icon>
    </Wrapper>
  );
};


export default ListItem;
