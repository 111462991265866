import PropTypes from "prop-types";
import React from "react";
import { withSize } from "react-sizeme";
import styled from "styled-components";
import { getColumnWidth } from "../../utils/theme";
import Path from "../PipeTrack/Path";

const Wrapper = styled.div`
    max-width: ${props => getColumnWidth(props.theme.breakpoints.desktop, 10)}px;
    margin: auto;
    position: relative;
    padding: ${props => props.paddingTop ? "60px" : 0} 10px;
`;

const Column = styled.div`
    max-width: 100%;
    margin: auto;
    position: relative;
    padding: 0 0 0 ${props => props.withoutPath ? 0 : "40px"};
    
    ${props => props.theme.tablet`
      max-width: ${props => getColumnWidth(props.theme.breakpoints.tablet, 7)}px;
      padding: 0;
    `}
    
    ${props => props.theme.desktop`
      max-width: ${props => getColumnWidth(props.theme.breakpoints.desktop, 8)}px;
    `}
`;

const CentreColumn = ({ withoutPath, paddingTop, size, children }) => (
  <Wrapper paddingTop={paddingTop}>
    {!withoutPath && (
      <Path
        d={`M0,0 L0,${size.height}`}
        height={size.height}
      />
    )}
    <Column withoutPath={withoutPath}>
      {children}
    </Column>
  </Wrapper>
);

CentreColumn.propTypes = {
  children: PropTypes.node.isRequired,
  withoutPath: PropTypes.bool,
  paddingTop: PropTypes.bool
};

CentreColumn.defaultProps = {
  withoutPath: false,
  paddingTop: false
};

export default withSize({ monitorHeight: true, noPlaceholder: true })(CentreColumn);
