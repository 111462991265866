import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import FadeIn from "../../FadeIn";
import DefinitionBarChart from "./DefinitionBarChart";

const DefinitionWrapper = styled.div`
  background-color: ${props => props.theme.colors.grey};
  padding: 30px;
  position: relative;
  
  ${props => props.theme.desktop`
    padding: 20px 15px 0;
  `}
`;

const Title = styled.dt`
  font-weight: bold;
  letter-spacing: -0.42px;
  padding-bottom: 10px;
  
  ${props => props.theme.fontSize(20)}
`;

const Description = styled.dd`
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.37px;
`;

const Stat = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.34px;
  color: ${props => props.theme.colors.pink};
  padding: 10px 0 20px;
`;

const TopRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  padding: 5px;
  border-left: 5px solid #fff;
  border-bottom: 5px solid #fff;
  
  svg {
    height: 100%;
  }
`;

const BottomRight = styled(TopRight)`
  bottom: 0;
  top: auto;
  border-top: 5px solid #fff;
  border-bottom: none;
`;

const Definition = ({ title, description, stat, bar, topRight, bottomRight }) => (
  <DefinitionWrapper>
    <FadeIn>
      <Title>{title}</Title>
      <Description>
        {description}
      </Description>

      {stat && (
        <Stat>
          {stat}
        </Stat>
      )}

      {bar && <DefinitionBarChart value={bar}/>}

      {topRight && (
        <TopRight>
          {topRight}
        </TopRight>
      )}

      {bottomRight && (
        <BottomRight>
          {bottomRight}
        </BottomRight>
      )}
    </FadeIn>
  </DefinitionWrapper>
);

Definition.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  stat: PropTypes.string,
  bar: PropTypes.number,
  topRight: PropTypes.node,
  bottomRight: PropTypes.node
};

export default Definition;
