import React from "react"
import { withSize } from "react-sizeme"
import styled, { withTheme } from "styled-components"
import CircleSVG from "../../images/shapes/circle.inline.svg"
import DiamondSVG from "../../images/shapes/diamond.inline.svg"
import RectangleFlipSVG from "../../images/shapes/rectangle-flip.inline.svg"
import RectangleSVG from "../../images/shapes/rectangle.inline.svg"
import HalfPipe from "../PipeTrack/HalfPipe"
import Definition from "./Definition"

const Wrapper = styled.div``

const Container = styled.div`
  margin: auto;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 7px;
  background: #ffffff;
  position: relative;
  z-index: 3;

  ${props => props.theme.tablet`
    max-width: ${props.theme.breakpoints.tablet.width}px;
    display: grid;
    grid-template-columns: 30fr 20fr;
  `}

  ${props => props.theme.desktop`
    max-width: ${props.theme.grid.containerWidth}px;
    grid-template-columns: 330px 1fr 430px;
  `}
`

const Column = styled.div`
  display: grid;
  grid-gap: 7px;
`

const InsideColumns = styled.div`
  display: grid;
  grid-gap: 7px;

  ${props => props.theme.tablet`
    grid-template-columns: 1fr 1fr;
  `}
`

const LastColumn = styled.div`
  display: grid;
  grid-gap: 7px;

  ${props => props.theme.tablet`
    grid-column: span 2;
  `}

  ${props => props.theme.desktop`
    grid-column: auto;
  `}
`

const Definitions = ({ size, theme }) => (
  <Wrapper>
    <Container>
      <Column>
        <Definition
          title="Equal opportunity"
          description="Equal opportunity defines the right to be treated without discrimination – including on the grounds of sex, race or age – giving everyone the same opportunities for employment, pay and promotion."
          stat="57% of people have heard of equal opportunity"
          bar={57}
          topRight={<DiamondSVG fill="#009696" />}
        />
        <Definition
          title="Gender pay gap"
          description="The gender pay gap is defined by the difference in salary when women are being paid less on average than men, across a region, sector or company. It's different to equal pay, a legal requirement that means men and women must receive equal pay for equal work."
          stat="Only 12% of people know what the gender pay gap is"
          bottomRight={<RectangleFlipSVG fill="#A94A7D" />}
        />
      </Column>
      <Column>
        <Definition
          title="Patriarchy"
          description="The patriarchy is a system of society or government in which men hold the power and women are largely excluded."
          stat="4 in 10 women and 3 in 10 men have heard of the patriarchy"
        />
        <Definition
          title="Intersectionality"
          description="Intersectionality describes the way that multiple forms of discrimination (such as racism, sexism, and classism) combine or overlap, especially in the experiences of marginalized groups."
          stat="Only 5% of people can define intersectionality"
          topRight={<CircleSVG fill="#40B0B0" />}
        />
      </Column>
      <LastColumn>
        <InsideColumns>
          <Definition
            title="Tokenism"
            description="Tokenism describes a symbolic effort to be inclusive, typically by recruiting a small number of people from minority groups to give the appearance of a diverse workforce."
            stat="Only 14% of people have heard of tokenism"
            bar={14}
            topRight={<CircleSVG />}
          />
          <Definition
            title="Glass cliff"
            description="The glass cliff occurs when a woman or member of a minority group is promoted to a position of power during a time of challenge or crisis, where the risk of failure is high."
            stat="Only 3% of people know what glass cliff means"
            bar={3}
          />
        </InsideColumns>
        <Definition
          title="Glass ceiling"
          description="The glass ceiling is an invisible barrier that stops women and members of minority groups progressing up the career ladder. This metaphor can refer to the effects of lack of representation, bias and unequal opportunities."
          stat="3 in 4 people haven’t heard of the term glass ceiling"
          topRight={<RectangleSVG />}
        />
      </LastColumn>
    </Container>

    {size.width < theme.breakpoints.desktop.width && <HalfPipe reverse />}
  </Wrapper>
)

export default withSize()(withTheme(Definitions))
