import styled from "styled-components";

const ChartTitle = styled.h3`
  ${props => props.theme.fontSize(20)}
  font-weight: bold;
  margin: 0;
  padding: 20px 0 0 0;
`;

export default ChartTitle;
