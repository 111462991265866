import React from "react";
import styled from "styled-components";
import { getColumnWidth } from "../../utils/theme";
import Illustration from "./Illustration";
import StartText from "./StartText";

const HeaderWrap = styled.div`
  text-align: center;
  position: relative;
  padding: 100px 0 250px;
`;

const Container = styled.div`
  max-width: ${props => props.theme.grid.containerWidth}px;
  margin: auto;
  position: relative;
  z-index: 2;
`;

const Column = styled.div`
  max-width: ${props => getColumnWidth(props.theme.breakpoints.mobile, 6)}px;
  margin: auto;
  padding: 0 20px;
  
  ${props => props.theme.tablet`
    max-width: ${props => getColumnWidth(props.theme.breakpoints.tablet, 8)}px;
    padding: 0;
  `}
  
  ${props => props.theme.desktop`
    max-width: ${props => getColumnWidth(props.theme.breakpoints.desktop, 8)}px;
  `}
`;

const Title = styled.h1`
  font-size: 60px;
  line-height: 68px;
  margin-bottom: 2rem;
`;

const Intro = styled.p`
  font-size: 24px;
  line-height: 34px;
`;

const Header = () => (
  <HeaderWrap>
    <Container>
      <Column>
        <Title>
          The Gender Pay Trap
        </Title>
        <Intro>
          Workplace inequality is costing women thousands of pounds each year. But it's not just a gender pay gap. It's
          a Gender Pay Trap: a vicious cycle of gender bias that runs through our modern work culture. The good news? We
          can begin to break out.
        </Intro>

        <StartText>Scroll To Start</StartText>
      </Column>
    </Container>

    <Illustration/>
  </HeaderWrap>
);

export default Header;
