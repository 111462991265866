import React from "react";
import styled from "styled-components";
import { useOnScreen } from "../../hooks/useOnScreen";
import Path from "../PipeTrack/Path";

const HEIGHT = 300;

const Wrapper = styled.div`
  height: ${HEIGHT}px;
  position: relative;
`;

const BoxWrapper = styled.div`
  width: 194px;
  height: 109px;
  margin: auto;
  background-color: ${props => props.theme.colors.grey};
  position: relative;
  z-index: 2;
  padding: 10px;
  transform: translateY(${HEIGHT - 109}px);
`;

const RectDiv = styled.div`
  position: absolute;
  ${props => props.top && `top: ${props.top}px;`}
  ${props => props.bottom && `bottom: ${props.bottom}px;`}
  ${props => props.left && `left: ${props.left}px;`}
  ${props => props.right && `right: ${props.right}px;`}
  width: ${props => props.dimensionWidth}px;
  height: ${props => props.dimensionHeight}px;
  background-color: ${props => props.bg};
  transition: all 400ms;
`;

const BoxBoxes = () => {
  const [ref, isOnScreen] = useOnScreen(0.6, false);

  return (
    <Wrapper ref={ref}>
      <BoxWrapper>
        <RectDiv top={10} right={isOnScreen ? 114 : 10}
                 dimensionWidth={isOnScreen ? 70 : 94}
                 dimensionHeight={isOnScreen ? 44.5 : 32}
                 bg="#F49164"/>
        <RectDiv top={isOnScreen ? 54.5 : 35} left={10}
                 dimensionWidth={isOnScreen ? 70 : 46}
                 dimensionHeight={isOnScreen ? 44.5 : 16}
                 bg="#F49164"/>

        <RectDiv bottom={10} left={isOnScreen ? 114 : 10}
                 dimensionWidth={isOnScreen ? 70 : 94}
                 dimensionHeight={isOnScreen ? 44.5 : 32}
                 bg="#40B0B0"/>
        <RectDiv bottom={isOnScreen ? 54.5 : 35} right={10}
                 dimensionWidth={isOnScreen ? 70 : 46}
                 dimensionHeight={isOnScreen ? 44.5 : 16}
                 bg="#40B0B0"/>
      </BoxWrapper>

      <Path height={HEIGHT} d={`M0,0 0,${HEIGHT}`} center/>
    </Wrapper>
  );
};

export default BoxBoxes;
