import React from "react"
import styled from "styled-components"
import { useOnScreen } from "../../hooks/useOnScreen"

const Wrapper = styled.div`
  padding-left: 120px;
  position: relative;
`

const SVG = styled.svg`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`

const Group = styled.g`
  transform: rotate(${props => props.spin}deg);
  transition: transform 2000ms;
  transform-origin: 46px 46px;
`

const Expect = () => {
  const [ref, isOnScreen] = useOnScreen(0.5, false)

  return (
    <Wrapper ref={ref}>
      <strong>Expect</strong>
      <p>
        Remember that women’s salary expectations are £5,600 lower than men’s on
        average. Women, ask for more!
      </p>

      <SVG width={92} height={92}>
        <rect
          fill="#F5F5F5"
          x="0.5"
          y="-4.54747351e-13"
          width="92"
          height="92"
        />
        <circle fill="#40B0B0" cx="46.5" cy="46" r="22" />

        <Group spin={isOnScreen ? 360 : 0}>
          <circle fill="#40B0B0" cx="46.5" cy="11" r="6" />
          <circle fill="#40B0B0" cx="80" cy="44" r="6" />
          <circle fill="#40B0B0" cx="13" cy="44" r="6" />
          <circle
            fill="#40B0B0"
            transform="translate(68.773864, 20.897709) rotate(-45.000000) translate(-68.773864, -20.897709) "
            cx="68.7738636"
            cy="20.8977093"
            r="6"
          />
          <circle
            fill="#40B0B0"
            transform="translate(21.397709, 68.273864) rotate(-45.000000) translate(-21.397709, -68.273864) "
            cx="21.3977093"
            cy="68.2738636"
            r="6"
          />
          <circle
            fill="#40B0B0"
            transform="translate(71.602291, 68.273864) rotate(45.000000) translate(-71.602291, -68.273864) "
            cx="71.6022907"
            cy="68.2738636"
            r="6"
          />
          <circle
            fill="#40B0B0"
            transform="translate(24.226136, 20.897709) rotate(45.000000) translate(-24.226136, -20.897709) "
            cx="24.2261364"
            cy="20.8977093"
            r="6"
          />
          <circle fill="#40B0B0" cx="46.5" cy="78" r="6" />
        </Group>
      </SVG>
    </Wrapper>
  )
}

export default Expect
