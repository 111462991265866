import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { getColumnWidth } from "../../utils/theme";
import BackgroundHighlight from "../BackgroundHighlight";
import FadeIn from "../FadeIn";
import LeftBox from "./LeftBox";
import List from "./List";

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  padding: 10px;
  
  ${props => props.theme.tablet`
    width: ${props => props.theme.breakpoints.tablet.width}px;
    display: flex;
    justify-content: space-between;
    padding: 0;
  `}
  
  ${props => props.theme.desktop`
    width: ${props => getColumnWidth(props.theme.breakpoints.desktop, 10)}px;
  `}
`;

const HowTo = ({ title, description, list }) => (
  <BackgroundHighlight>
    <FadeIn>
      <Wrapper>
        <div>
          <LeftBox title={title} description={description}/>
        </div>

        <List items={list}/>
      </Wrapper>
    </FadeIn>
  </BackgroundHighlight>
);

HowTo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired
};

export default HowTo;
