import groupBy from "lodash/groupBy"
import orderBy from "lodash/orderBy"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"
import ChartTitle from "../ChartTitle"
import FadeIn from "../FadeIn"
import GapBarChart from "./GapBarChart"
import ToggleState from "./ToggleSwitch"

const Wrapper = styled.div`
  padding: 40px 0;
  margin: 0;
  position: relative;
`

const Header = styled.div`
  ${props => props.theme.tablet`
    display: flex;
    justify-content: space-between;
  `}
`

const GapChart = ({ config }) => {
  const {
    title,
    data,
    axis,
    years,
    prefix,
    suffix,
    difference,
    menBelow,
  } = config
  const [year, setYear] = useState(years[1])
  const orderedData = orderBy(data, "value")
  const grouped = groupBy(orderedData, "year")

  return (
    <FadeIn>
      <Wrapper>
        <Header>
          <ChartTitle>{title}</ChartTitle>

          <ToggleState selected={year} options={years} onSelect={setYear} />
        </Header>

        <GapBarChart
          axis={axis}
          data={grouped[year]}
          prefix={prefix}
          suffix={suffix}
          difference={difference}
          menBelow={menBelow}
        />
      </Wrapper>
    </FadeIn>
  )
}

GapChart.propTypes = {
  config: PropTypes.object.isRequired,
}

export default GapChart
