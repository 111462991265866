import PropTypes from "prop-types";
import React from "react";
import CountUp from "react-countup";
import styled from "styled-components";
import { useOnScreen } from "../../hooks/useOnScreen";

const Wrapper = styled.div`

`;

const Title = styled.span`
  display: block;
  font-size: 44px;
  line-height: 53px;
  font-weight: bold;
`;

const Number = styled.span.attrs({
  "aria-hidden": true
})`
  display: block;
  font-size: 54px;
  line-height: 66px;
  font-weight: bold;
  font-variant-numeric: tabular-nums;
`;

const Text = styled.span`
  display: block;
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
`;

const SidebarStat = ({ number, title, text, prefix, suffix }) => {
  const [ref, onScreen] = useOnScreen();

  return (
    <Wrapper ref={ref}>
      {title && (
        <Title>
          <span>{title}</span>
        </Title>
      )}

      {number !== -1 && (
        <Number>
          <CountUp end={onScreen ? number : 0} prefix={prefix} suffix={suffix} separator="," decimals={0} preserveValue/>
        </Number>
      )}

      <Text>
        {number !== -1 && <span className="sr-only">{prefix}{number}{suffix}</span>}
        {text}
      </Text>
    </Wrapper>
  );
};

SidebarStat.propTypes = {
  number: PropTypes.number,
  title: PropTypes.string,
  text: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string
};

SidebarStat.defaultProps = {
  number: -1,
  prefix: "",
  suffix: ""
};

export default SidebarStat;
