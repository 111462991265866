import React from "react";
import styled, { keyframes } from "styled-components";
import { useOnScreen } from "../../hooks/useOnScreen";
import Path from "../PipeTrack/Path";

const HEIGHT = 300;

const moveDown = keyframes`
    0% {transform: translate(0, 0px);}
    100% {transform: translate(0, 76px);}
`;

const Wrapper = styled.div`
  position: relative;
  height: ${HEIGHT}px;
  margin: auto;
`;

const BoxWrapper = styled.div`
  width: 138px;
  height: 96px;
  margin: auto;
  background-color: ${props => props.theme.colors.grey};
  z-index: 2;
  position: relative;
  transform: translateY(${HEIGHT - 96}px);
`;

const Fade = styled.path`
  opacity: ${props => props.isOnScreen ? 0 : 1};
  transition: 500ms;
`;

const RazorGroup = styled.g`
  animation: infinite ${moveDown} 1s linear;
  fill: #f7b597;
`;

const BoxQuestion = () => {
  const [ref, isOnScreen] = useOnScreen(0.6, false);

  return (
    <Wrapper ref={ref}>

      <BoxWrapper>
        <svg width={138} height={96}>
          <RazorGroup>
            <path d="M23,76 L9,57 23,38 9,19 23,0 H0l0 76z" transform="translate(0 -75)"/>
            <path d="M23,76 L9,57 23,38 9,19 23,0 H0l0 76z"/>
            <path d="M23,76 L9,57 23,38 9,19 23,0 H0l0 76z" transform="translate(0 75)"/>
          </RazorGroup>
          <RazorGroup>
            <path d="M115,76 L129,57 115,38 129,19 115,0 H138l138 76z" transform="translate(0 -75)"/>
            <path d="M115,76 L129,57 115,38 129,19 115,0 H138l138 76z"/>
            <path d="M115,76 L129,57 115,38 129,19 115,0 H138l138 76z" transform="translate(0 75)"/>
          </RazorGroup>

          <Fade fill="#F49164"
                isOnScreen={isOnScreen}
                d="M58.84 87.991h16.556V71.784H58.84v16.207zm.349-24.657V44.927h9.49c7.179 0 12.967-4.4 12.967-11.808 0-7.06-4.63-11.695-11.696-11.695-6.48 0-11.228 3.937-11.921 9.957H42.75C43.791 17.373 53.051 8 69.258 8c15.28 0 27.088 9.952 27.088 25.001 0 12.736-8.681 20.725-21.186 22.232v8.101H59.189z"/>
        </svg>
      </BoxWrapper>

      <Path height={HEIGHT} d={`M0,0 L0,${HEIGHT}`} center/>
    </Wrapper>
  );
};

export default BoxQuestion;
