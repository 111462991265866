import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { useOnScreen } from "../../../hooks/useOnScreen"
import AxisLine from "./AxisLine"
import Difference from "./Difference"
import GenderLabel from "./GenderLabel"

const Wrapper = styled.div`
  padding: 80px 0;
`

const BarWrapper = styled.div`
  background-color: ${props => props.theme.colors.grey};
  width: 100%;
  height: 50px;
  position: relative;
`

const Chart = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  left: ${props => props.left}%;
  width: ${props => props.chartWidth}%;
  transition: left ${props => props.theme.animations.duration}ms,
    width ${props => props.theme.animations.duration}ms;
`

const GapBarChart = ({ axis, data, prefix, suffix, difference, menBelow }) => {
  const { min, max } = axis
  const [ref, onScreen] = useOnScreen()
  const [women, men] = data

  let left = ((women.value - min) / (max - min)) * 100
  let right = ((men.value - min) / (max - min)) * 100
  let width = right - left

  if (!onScreen) {
    left = 50
    width = 0
  }

  return (
    <Wrapper ref={ref}>
      <BarWrapper>
        <AxisLine value={min} prefix={prefix} suffix={suffix} left />
        <AxisLine value={max} prefix={prefix} suffix={suffix} right />

        <Chart left={left} chartWidth={width}>
          {data.map(({ value, gender, align }) => (
            <GenderLabel
              key={gender}
              value={value}
              gender={gender}
              suffix={suffix}
              prefix={prefix}
              onScreen={onScreen}
              align={align}
              menBelow={menBelow}
            />
          ))}

          {difference && (
            <Difference
              data={data}
              prefix={prefix}
              suffix={suffix}
              onScreen={onScreen}
            />
          )}
        </Chart>
      </BarWrapper>
    </Wrapper>
  )
}

GapBarChart.propTypes = {
  axis: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  difference: PropTypes.bool,
  minWidth: PropTypes.number,
}

GapBarChart.defaultProps = {
  prefix: "",
  suffix: "",
  difference: false,
}

export default GapBarChart
