import PropTypes from "prop-types";
import React, { useState } from "react";
import { withSize } from "react-sizeme";
import styled, { withTheme } from "styled-components";
import { getColumnWidth } from "../../../utils/theme";
import Path from "../../PipeTrack/Path";
import SidebarWrapper from "./SidebarWrapper";

const Wrapper = styled.div`
  max-width: ${props => getColumnWidth(props.theme.breakpoints.desktop, 10)}px;
  margin: auto;
  padding: 0 10px;
`;

const ContentWrapper = styled.div`
  ${props => props.theme.tablet`
    display: flex;
    align-items: start;
  `}
`;

const Content = styled.div`
  padding-left: 40px;
  max-width: ${props => getColumnWidth(props.theme.breakpoints.desktop, 6)}px;
  
  ${props => props.theme.tablet`
    padding-left: 20px;
  `}
`;

const Sidebar = ({ sidebar, endLeft, children, size, theme }) => {
  const [sidebarSize, setSidebarSize] = useState({});
  const pathHeight = sidebarSize.height + 30;
  const pathWidth = sidebarSize.width - (Path.padding * 2);
  const isMobile = size.width <= theme.breakpoints.tablet.width;

  let d = `M0,0 L0,${pathHeight} L${pathWidth},${pathHeight}`;

  if (endLeft) {
    d = `${d} L${pathWidth},${size.height - 50} L0,${size.height - 50} L0,${size.height}`;
  } else {
    d = `${d} L${pathWidth},${size.height + 50}`;
  }

  // if we're on mobile its a whole different path
  if (isMobile) {
    d = `M0,0 L0,${Path.padding} L80,${Path.padding} L80,${sidebarSize.height - Path.padding} L0,${sidebarSize.height - Path.padding} L0,${size.height + 50}`;
  }

  const onSize = ({ height, width }) => {
    setSidebarSize({ width, height });
  };

  return (
    <Wrapper>
      <Path
        height={size.height}
        d={d}
      />

      <ContentWrapper>

        <SidebarWrapper onSize={onSize}>
          {sidebar}
        </SidebarWrapper>

        <Content>
          {children}
        </Content>

      </ContentWrapper>
    </Wrapper>
  );
};

Sidebar.propTypes = {
  sidebar: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  endLeft: PropTypes.bool
};

Sidebar.defaultProps = {
  endLeft: false
};

export default withSize({ monitorHeight: true, noPlaceholder: true })(withTheme(Sidebar));
