import React from "react"
import CountUp from "react-countup"
import styled from "styled-components"

const Label = styled.span`
  position: absolute;
  top: -100%;
  text-align: center;
  color: ${props =>
    props.gender === "Men" ? props.theme.colors.men : props.theme.colors.women};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 500ms;

  ${props =>
    props.gender === "Women" &&
    `
    left: 0;
    transform: translateX(-50%);
  `}
  ${props =>
    props.gender === "Men" &&
    `
    right: 0;
    transform: translateX(50%);
  `}

  ${props =>
    props.menBelow
      ? `
      ${
        props.align === "left"
          ? `
        left: 100%;
        right: auto;
        transform: none;
      `
          : ``
      }
      ${
        props.align === "right"
          ? `
        right: 100%;
        left: auto;
        transform: none;
      `
          : ``
      }
    `
      : ``}

  ${props => props.theme.tabletDown`
    ${props.align === "left" &&
      `
      left: 100%;
      right: auto;
      transform: none;
    `}
    ${props.align === "right" &&
      `
      right: 100%;
      left: auto;
      transform: none;
    `}
  `}

  span {
    display: block;
    font-weight: bold;
    font-variant-numeric: tabular-nums;
    font-size: 16px;
    line-height: 19px;

    ${props => props.theme.tablet`
        font-size: 18px;
        line-height: 21px;
    `}
  }
`

const Line = styled.div`
  position: absolute;
  height: 100%;
  width: 5px;
  z-index: 2;
  background-color: ${props =>
    props.gender === "Men" ? props.theme.colors.men : props.theme.colors.women};
  ${props => props.gender === "Women" && "left: 0;"}
  ${props => props.gender === "Men" && "right: 0;"}
`

const GenderLabel = ({
  gender,
  value,
  onScreen,
  prefix,
  suffix,
  align,
  menBelow,
}) => {
  let labelAlign = align

  if (!labelAlign) {
    if (gender === "Men") {
      labelAlign = "right-center"
    } else {
      labelAlign = "left-center"
    }
  }

  return (
    <>
      <Label
        gender={gender}
        align={labelAlign}
        visible={onScreen}
        menBelow={menBelow}
      >
        <span>{gender}</span>
        <CountUp
          end={onScreen ? value : 0}
          prefix={prefix}
          suffix={suffix}
          separator=","
          decimals={0}
          preserveValue
        />
      </Label>
      <Line gender={gender} />
    </>
  )
}

export default GenderLabel
