import React from "react";
import styled from "styled-components";
import Path from "./Path";

const HEIGHT = 90;

const Wrapper = styled.div`
    height: ${HEIGHT}px;
    background-color: ${props => props.theme.colors.grey};
    margin: auto;
    position: relative;
    width: 35px;
`;

const PipeStart = () => (
  <Wrapper>
    <Path
      d={`M0,10 L0,${HEIGHT}`}
      height={HEIGHT}
    />
  </Wrapper>
);

export default PipeStart;
