import React from "react";
import styled from "styled-components";
import Circle from "../../../images/shapes/circle.inline.svg";
import Diamond from "../../../images/shapes/diamond.inline.svg";
import RectangleFlip from "../../../images/shapes/rectangle-flip.inline.svg";
import Rectangle from "../../../images/shapes/rectangle.inline.svg";
import ListItem from "./ListItem";

const icons = [
  <Circle width={22}/>,
  <Rectangle width={22}/>,
  <Diamond width={22}/>,
  <Circle fill="#F7B597" width={22}/>,
  <RectangleFlip width={22}/>,
  <Diamond fill="#009696" width={22}/>
];

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  
  ${props => props.theme.tablet`
    width: 400px;
  `}
  
  ${props => props.theme.desktop`
    width: 530px;
  `}
`;

const LeftColumn = styled.div`
  min-width: 50px;
  max-width: 50px;
  border: 7px solid #fff;
  position: relative;
`;

const ListWrapper = styled.div`
  padding: 20px;
`;

const List = ({ items }) => (
  <Wrapper>
    <LeftColumn/>

    <ListWrapper>
      {items.map((Item, index) => (
        <ListItem key={index} item={Item} icon={icons[index % icons.length]}/>
      ))}
    </ListWrapper>
  </Wrapper>
);

export default List;
