import PropTypes from "prop-types";
import React from "react";
import { withSize } from "react-sizeme";
import styled from "styled-components";
import { getColumnWidth } from "../../utils/theme";
import FadeIn from "../FadeIn";
import Path from "../PipeTrack/Path";

const Wrapper = styled.div`
  max-width: ${props => getColumnWidth(props.theme.breakpoints.desktop, 10)}px;
  position: relative;
  padding: 190px 0 0 40px;
  margin: auto;
    
  ${props => props.theme.tablet`
    padding: 190px 0 0 0;
  `}
`;

const Stage = styled.span`
  display: block;
  font-weight: bold;
  text-align: center;
  padding: 0;
  
  ${props => props.theme.fontSize(34)}
`;

const Title = styled.h2`
  font-weight: bold;
  text-align: center;
  padding-bottom: 2rem;
  margin: 0;
  
  ${props => props.theme.fontSize(34)}
`;

const StageTitle = ({ stage, title, size }) => {
  const { width, height } = size;
  const leftPosition = (width / 2) - Path.padding;

  return (
    <Wrapper>
      <Path
        d={`M0,0 L0,100 L-${leftPosition},100 L-${leftPosition},${height}`}
        height={height}
        center
      />

      <FadeIn>
        <Stage>Stage {stage}</Stage>
        <Title>{title}</Title>
      </FadeIn>
    </Wrapper>
  );
};

StageTitle.propTypes = {
  stage: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired
};

export default withSize({ monitorHeight: true })(StageTitle);
