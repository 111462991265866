import PropTypes from "prop-types";
import React from "react";
import DotSVG from "./dot.inline.svg";

const Dot = ({ width, height }) => (
  <DotSVG width={width} height={height}/>
);

Dot.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

Dot.defaultProps = {
  width: 25,
  height: 25
};

export default Dot;
