import React from "react";
import styled from "styled-components";
import { getColumnWidth } from "../../utils/theme";

const Wrapper = styled.div`
    width: 70%;
    height: 90px;
    margin: auto;
    background: #f5f5f5;
    position: relative;
    
    ${props => props.theme.tablet`
      width: ${props => getColumnWidth(props.theme.breakpoints.tablet, 4)}px;
    `}
    
    ${props => props.theme.desktop`
      width: ${props => getColumnWidth(props.theme.breakpoints.desktop, 6)}px;
    `}
`;

const SVGWrapper = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  padding: 5px;
  background-color: ${props => props.theme.colors.grey};
  
  ${props => props.bottom !== undefined ? `bottom: ${props.bottom}px;` : ""}
  ${props => props.top !== undefined ? `top: ${props.top}px;` : ""}
  ${props => props.left && `left: ${props.left}px;`}
  ${props => props.right && `right: ${props.right}px;`}
  
  svg {
    width: 100%;
    height: 100%;
  }
`;

const Shorter = ({ topLeft, topRight, bottomLeft, bottomRight }) => (
  <Wrapper>
    {topLeft && (
      <SVGWrapper top={5} left={-45}>
        {topLeft}
      </SVGWrapper>
    )}
    {topRight && (
      <SVGWrapper top={5} right={-45}>
        {topRight}
      </SVGWrapper>
    )}
    {bottomLeft && (
      <SVGWrapper bottom={5} left={-45}>
        {bottomLeft}
      </SVGWrapper>
    )}
    {bottomRight && (
      <SVGWrapper bottom={5} right={-45}>
        {bottomRight}
      </SVGWrapper>
    )}
  </Wrapper>
);

export default Shorter;
