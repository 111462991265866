import React from "react";
import { withSize } from "react-sizeme";
import styled from "styled-components";
import { getColumnWidth } from "../../../utils/theme";
import ArrowsDown from "../../Arrows/ArrowDown";

const Wrapper = styled.div`
  width: 100%;
  padding: 0 0 0 120px;
  position: relative;
  margin-bottom: 40px;
  
  ${props => props.theme.tablet`
    max-width: ${props => getColumnWidth(props.theme.breakpoints.desktop, 3)}px;
    padding: 0 0 0 40px;
    margin: 0;
  `}
`;

const StyledArrowsDownTop = styled.div`
  position: absolute;
  top: -50px;
  left: 0;
  width: 20px;
  
  ${props => props.theme.tablet`
    top: 0;
    left: -2px;
  `}
`;

const StyledArrowsDownBottom = styled.div`
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 20px;
  
  ${props => props.theme.tablet`
    bottom: -100px;
    left: auto;
    right: 17.5px;
  `}
`;

const SidebarWrapper = ({ children }) => (
  <Wrapper>
    {children}

    <StyledArrowsDownTop>
      <ArrowsDown fill="#A94A7D"/>
    </StyledArrowsDownTop>

    <StyledArrowsDownBottom>
      <ArrowsDown fill="#F49164"/>
    </StyledArrowsDownBottom>
  </Wrapper>
);

export default withSize({ monitorHeight: true })(SidebarWrapper);
