import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledLink = styled.a`
  font-weight: normal;
  color: #292A36;
  cursor: pointer;
  position: relative;
  border-bottom: 2px solid #3CBF0A;
  padding-bottom: 2px;
  transition: padding-bottom 300ms;
  
  &:hover {
    color: #292A36;
    text-decoration: none;
    padding-bottom: 0;
  }
`;

const Link = ({ href, target, children }) => (
  <StyledLink href={href} target={target} rel="noopener noreferrer">
    {children}
  </StyledLink>
);

Link.propTypes = {
  href: PropTypes.string.isRequired,
  target: PropTypes.string
};

Link.defaultProps = {
  target: "_blank"
};

export default Link;
