import forReach from "lodash/forEach";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { pathClassName } from "../../data/config";
import Dot from "../Dot";

const DotWrap = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
`;

const PipeDot = () => {
  const dotRef = useRef();

  const setCirclePosition = ({ x, y }) => {
    const { current } = dotRef;
    const { width, height } = current.getBoundingClientRect();

    current.style.left = `${x - (width / 2)}px`;
    current.style.top = `${y - (height / 2) + window.scrollY}px`;
  };

  const setCircleForceShow = (show = false) => {
    const { current } = dotRef;

    current.style.zIndex = show ? 10 : 3;
  };

  const setCircleFade = (percentage, fading = false) => {
    const { current } = dotRef;

    // exit early if we're not fading
    if (!fading) {
      current.style.opacity = 1;
      return;
    }

    // if we are fading
    if (percentage < 0.2) {
      current.style.opacity = percentage / 0.2;
      return;
    }

    if (percentage > 1) {
      current.style.opacity = 0;
      return;
    }

    if (percentage > 0.8) {
      current.style.opacity = Math.abs(((percentage - 0.8) / 0.2) - 1);
      return;
    }

    current.style.opacity = 1;
  };

  useEffect(() => {
    const onScroll = () => {
      const breakpoint = document.documentElement.clientHeight / 3;

      const pathElements = document.getElementsByClassName(pathClassName);
      let pathElement = pathElements[0];

      if (!pathElement) {
        return;
      }

      forReach(pathElements, (element) => {
        const { top } = element.getBoundingClientRect();

        if (top < breakpoint) {
          pathElement = element;
        }
      });

      const { left, top } = pathElement.getBoundingClientRect();
      const { height } = pathElement.getBBox();
      const parentBox = pathElement.parentElement.getBoundingClientRect();

      if (top > breakpoint) {
        const point = pathElement.getPointAtLength(0);

        setCirclePosition({
          x: point.x + left,
          y: point.y + top
        });

        return;
      }

      const percentage = Math.abs((top - breakpoint) / height);
      const length = pathElement.getTotalLength();

      if (length > 0) {
        const point = pathElement.getPointAtLength(length * percentage);
        const forceShow = pathElement.getAttribute("data-force-show");
        const fade = pathElement.getAttribute("data-fade");

        setCircleForceShow(forceShow == "true");

        setCircleFade(percentage, fade == "true");

        setCirclePosition({
          x: point.x + parentBox.left,
          y: point.y + parentBox.top
        });
      }
    };

    document.addEventListener("scroll", onScroll);
    window.addEventListener("resize", onScroll);

    onScroll();

    return () => {
      document.removeEventListener("scroll", onScroll);
      window.removeEventListener("resize", onScroll);
    };
  }, []);

  return (
    <DotWrap ref={dotRef}>
      <Dot/>
    </DotWrap>
  );
};

export default PipeDot;
