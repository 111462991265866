import PropTypes from "prop-types";
import React from "react";
import CountUp from "react-countup";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  background-color: #8B8FA0;
  width: 2px;
  height: 60px;
  top: 0;
  ${props => props.left && "left: 0;"}
  ${props => props.right && "right: 0;"}
  
  span {
    color: #5F5F60;
    position: absolute;
    bottom: -30px;
    ${props => props.left && "left: 0;"}
    ${props => props.right && "right: 0;"}
  }
`;


const AxisLine = ({ value, left, right, prefix, suffix }) => (
  <Wrapper left={left} right={right}>
    <CountUp prefix={prefix} suffix={suffix} end={value} separator="," decimals={0}/>
  </Wrapper>
);

AxisLine.propTypes = {
  value: PropTypes.number.isRequired,
  left: PropTypes.bool,
  right: PropTypes.bool
};

AxisLine.defaultProps = {
  left: false,
  right: false
};

export default AxisLine;
