import React from "react"
import styled from "styled-components"
import Link from "../Link"
import Explore from "./Explore"
import FooterNav from "./FooterNav"

const FooterWrapper = styled.div`
  padding-top: 45px;
  position: relative;
  z-index: 2;

  ${props => props.theme.tablet`
    padding-top: 150px;
  `}
`

const Container = styled.div`
  max-width: 100%;
  margin: auto;
  padding: 0 10px;

  ${props => props.theme.tablet`
    max-width: ${props.theme.breakpoints.tablet.width}px;
  `}

  ${props => props.theme.desktop`
    max-width: ${props.theme.breakpoints.desktop.width}px;
  `}
`

const Wrapper = styled.div`
  padding: 60px 0;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: -0.29px;
`

const Copy = styled.p`
  padding: 10px 0 0;
  margin: 0;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const ListItem = styled.li`
  padding-left: 50px;
  position: relative;

  &:before {
    content: "-";
    position: absolute;
    left: 20px;
  }
`

const Footer = () => (
  <FooterWrapper>
    <Explore />
    <Container>
      <Wrapper>
        <List>
          <ListItem>
            Advice from Gillian Ku, Professor of Organisational Behaviour at{" "}
            <Link href="https://www.london.edu/">London Business School</Link>
          </ListItem>
          <ListItem>
            Financial rewards and expectations data: Totaljobs research of 2,197
            UK workers in November 2019 and 5,232 UK workers in October 2021
          </ListItem>
          <ListItem>
            Job ads data: 1,862,115 Totaljobs Group ads from August 2017,
            October 2018 and October 2019, and Dec 2020 - May 2021.
          </ListItem>
          <ListItem>
            Gender bias:
            <List>
              <ListItem>
                'Female-biased’ job ads have more female-coded words than
                male-coded
              </ListItem>
              <ListItem>
                ‘Male-biased’ job ads have more male-coded words than
                female-coded
              </ListItem>
              <ListItem>
                'Balanced' job ads are those with no gender-flagged words or an
                equal number of male- and female-flagged words.
              </ListItem>
              <ListItem>
                For more information on methodology, see the{" "}
                <Link href="https://www.totaljobs.com/insidejob/gender-bias-decoder">
                  Gender Bias Decoder
                </Link>
              </ListItem>
            </List>
          </ListItem>
          <ListItem>Numbers may not add up to 100% due to rounding</ListItem>
        </List>
        <Copy>
          Created in collaboration with{" "}
          <Link href="https://beyondwordsstudio.com/">Beyond Words Studio</Link>
          .
        </Copy>
      </Wrapper>

      <FooterNav />
    </Container>
  </FooterWrapper>
)

export default Footer
