import React from "react";
import styled from "styled-components";
import { useOnScreen } from "../../hooks/useOnScreen";
import Path from "../PipeTrack/Path";

const Wrapper = styled.div`
  height: 300px;
  margin: auto;
  position: relative;
`;

const BoxWrapper = styled.div`
  width: 139px;
  height: 80px;
  margin: auto;
  background-color: ${props => props.theme.colors.grey};
  z-index: 2;
  position: relative;
  transform: translate(-52px, 100px);
`;

const Diagonal = styled.path`
  fill: #A94A7D;
  transform: translateX(${props => props.isOnScreen ? -5 : -110}px);
  transition: transform 300ms;
`;

const BoxDiagonal = () => {
  const [ref, isOnScreen] = useOnScreen(0.6, false);

  return (
    <Wrapper ref={ref}>

      <Path height={100} d={`M-104,0 L-104,100`} center/>
      <Path height={80} d={`M-104,100 L0,180`} center noPipe/>
      <Path height={100} d={`M0,180 L0,300`} center/>

      <BoxWrapper>
        <svg width="104" height="80">
          <Diagonal d="M104 80L0 0v80z" isOnScreen={isOnScreen}/>
        </svg>
      </BoxWrapper>

    </Wrapper>
  );
};

export default BoxDiagonal;
