import React from "react";
import styled from "styled-components";
import MiddleSVG from "../../../images/header/middle.inline.svg";
import SideSVG from "../../../images/header/side.svg";
import Contents from "./Contents";

const Wrap = styled.div`
  z-index: 1;
  position: relative;
  margin-top: -100px;
  height: 300px;
  
  ${props => props.theme.tablet`
    margin-top: -220px;
    height: 820px;
  `} 
`;

const SVGWrap = styled.div`
  position: absolute;
  top: 0;
  width: 600px;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  
  ${props => props.theme.tablet`
    width: 1200px;
  `} 
`;

const MiddleSVGWrap = styled.div`
  position: relative;
`;

const LeftSVG = styled.div`
  position: absolute;
  height: 100%;
  width: 508px;
  background: url(${SideSVG}) repeat-x right top;
  transform: translateX(-100%);
  top: 0;
  left: 0;
`;

const RightSVG = styled.div`
  position: absolute;
  height: 100%;
  width: 508px;
  background: url(${SideSVG}) repeat-x right top;
  transform: translateX(100%);
  top: 0;
  right: 0;
`;

const Illustration = () => (
  <Wrap>
    <SVGWrap>
      <MiddleSVGWrap>
        <MiddleSVG/>
        <Contents/>
      </MiddleSVGWrap>

      <LeftSVG/>
      <RightSVG/>
    </SVGWrap>
  </Wrap>
);

export default Illustration;
