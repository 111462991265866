import PropTypes from "prop-types"
import React from "react"
import { withSize } from "react-sizeme"
import styled from "styled-components"
import { useOnScreen } from "../../hooks/useOnScreen"
import ChartTitle from "../ChartTitle"
import FadeIn from "../FadeIn"
import Square from "./Square"

const Wrapper = styled.div`
  padding: 40px 0;
  margin: 0;
  position: relative;
`

const AreaWrap = styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.size / 2}px;
  margin: 0 0 20px;

  margin-top: ${props => (props.lessThan50 ? "-10%" : 0)};
  ${props => props.theme.tablet`
  `}
`

const SquareaChart = ({ config, size, lessThan50 }) => {
  const [ref, onScreen] = useOnScreen()
  const { title, data } = config
  const [women, men] = data

  const { width } = size

  return (
    <Wrapper ref={ref}>
      <FadeIn>
        <ChartTitle>{title}</ChartTitle>

        <AreaWrap size={width} lessThan50={lessThan50}>
          <Square data={women} onScreen={onScreen} left />
          <Square data={men} onScreen={onScreen} right />
        </AreaWrap>
      </FadeIn>
    </Wrapper>
  )
}

SquareaChart.propTypes = {
  config: PropTypes.object.isRequired,
}

export default withSize()(SquareaChart)
