import React from "react";
import styled from "styled-components";
import { useOnScreen } from "../../hooks/useOnScreen";

const Wrapper = styled.div`
  padding-left: 120px;
  position: relative;
`;

const SVG = styled.svg`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const Group = styled.g`
  transform: rotate(${props => props.spin}deg);
  transition: transform 1000ms;
  transform-origin: 46px 46px;
`;

const Circle = styled.circle`
  transform: translate(${props => props.translate});
  transition: transform 3000ms;
`;

const Negotiate = () => {
  const [ref, isOnScreen] = useOnScreen(0.5, false);

  return (
    <Wrapper ref={ref}>
      <strong>Negotiate</strong>
      <p>
        Plan out a polite, confident request for an appropriate pay rise or bonus. Now deliver it. Remind yourself
        that you’re negotiating for all women.
      </p>

      <SVG width={92} height={92}>
        <rect fill="#F5F5F5" x="0" y="0" width="92" height="92"/>

        <Group spin={isOnScreen ? -30 : 0}>
          <rect fill="#A94A7D" transform="translate(46, 51) rotate(15) translate(-46, -51)"
                x="6" y="46" width="80" height="10"/>

          <Circle fill="#40B0B0" cx="75" cy="44" r="7" translate={isOnScreen ? "-55px, -15px" : "0, 0"}/>
        </Group>
      </SVG>
    </Wrapper>
  );
};

export default Negotiate;
