import React, { useState } from "react"
import { Element, scroller } from "react-scroll/modules"
import BodyText from "../components/BodyText"
import BoxBoxes from "../components/Boxes/BoxBoxes"
import BoxDiagonal from "../components/Boxes/BoxDiagonal"
import BoxQuestion from "../components/Boxes/BoxQuestion"
import BoxRectangle from "../components/Boxes/BoxRectangle"
import BoxTeardrops from "../components/Boxes/BoxTeardrops"
import Conclusion from "../components/Conclusion"
import Congratulations from "../components/Congratulations"
import Definitions from "../components/Definitions"
import Footer from "../components/Footer"
import GapChart from "../components/GapChart"
import CentreColumn from "../components/Grid/CentreColumn"
import Sidebar from "../components/Grid/Sidebar"
import Header from "../components/Header"
import HowTo from "../components/HowTo"
import Layout from "../components/Layout"
import Link from "../components/Link"
import ListChart from "../components/ListChart"
import MirrorBarChart from "../components/MirrorBarChart"
import HalfPipe from "../components/PipeTrack/HalfPipe"
import PipeDot from "../components/PipeTrack/PipeDot"
import PipeStart from "../components/PipeTrack/PipeStart"
import Seo from "../components/Seo"
import SidebarStat from "../components/SidebarStat"
import SquareaChart from "../components/SquareaChart"
import StackedBarChart from "../components/StackedBarChart"
import StageTitle from "../components/StageTitle"
import {
  femaleBiasedIndustries,
  genderBiasedSenior,
  genderBonusGap,
  genderEquality,
  genderFairPay,
  genderPayRise,
  genderSalary,
  maleBiasedIndustries,
  payInequalityJobSatisfaction,
  payRiseList,
  salaryJobSatisfaction,
  uncomfortablePayRise,
  unequalPayQuit,
} from "../data/charts"

const IndexPage = () => {
  const [showEscapeBar, setShowEscapeBar] = useState(false)
  const [showConclusion, setShowConclusion] = useState(false)

  const onEscape = () => {
    setShowEscapeBar(false)
    setShowConclusion(true)

    setTimeout(() => {
      scroller.scrollTo("conclusion", {
        duration: 1000,
        smooth: "easeInOutQuart",
        offset: -200,
      })
    }, 10)
  }

  return (
    <Layout showEscapeBar={showEscapeBar} onEscape={onEscape}>
      <Seo />

      <PipeDot />

      <Header />

      <Congratulations />

      <PipeStart />

      <Element id={`stage-1`}>
        <BoxQuestion />

        <StageTitle stage={1} title="Don’t ask, don’t get" />
      </Element>

      <CentreColumn>
        <BodyText>
          For many of us, salaries are negotiated. Here’s the first problem: our
          survey of over 5,000 UK workers found that women’s salary expectations
          are significantly lower than men’s.
        </BodyText>
        <BodyText>
          On average, men ask for 20% more than women when they start a new job.
        </BodyText>

        <GapChart config={genderSalary} />
      </CentreColumn>

      <Sidebar
        sidebar={
          <SidebarStat
            number={5597}
            prefix="£"
            text="is how much lower women’s salary expectations are than men's"
          />
        }
      >
        <BodyText>
          Despite the fact that the gender pay gap is widely acknowledged, the
          gap in salary expectations hasn’t really improved in the last four
          years.
        </BodyText>
        <BodyText>So what can we do about this?</BodyText>
        <BodyText>
          It can often feel difficult to ask for a higher salary. And of course,
          it’s not quite as simple as adding £5,600 to your initial expectation
          - what you ask should be dictated by what the job is worth.
        </BodyText>
      </Sidebar>

      <HowTo
        title={"How to negotiate a salary"}
        description={
          "Gillian Ku, Professor of Organisational Behaviour at London Business School, offers some advice"
        }
        list={[
          <p>
            <strong>Set yourself an optimistic but realistic goal.</strong> Keep
            in mind that to achieve your goal, you probably want to start by
            asking for even more than that. Be ready for some back and forth.
          </p>,
          <p>
            <strong>Do your homework.</strong> Find out what offers normally
            look like for these positions, and what you are worth. You could
            start by using a{" "}
            <Link href="https://www.totaljobs.com/salary-checker/salary-calculator">
              salary checker
            </Link>
            . Decide on a walk-away point before you start.
          </p>,
          <p>
            <strong>Negotiate package deals, not just single issues.</strong>{" "}
            Think about things other than salary – for example: holiday, hours,
            bonuses,{" "}
            <Link href="http://lbsresearch.london.edu/1064/">
              remote working
            </Link>
            , scheduled pay reviews, equity and training opportunities.
          </p>,
          <p>
            <strong>Try to make the first offer</strong> if the context allows.
          </p>,
          <p>
            <strong>Improve your alternatives.</strong> Your negotiating
            strength and personal confidence comes from having alternatives
            (particularly alternative job offers) that you’re happy with.
          </p>,
          <p>
            <strong>Don’t get caught off-guard.</strong> Be ready to handle all
            of this during the interview process.
          </p>,
        ]}
      />

      <BoxDiagonal />

      <StageTitle stage={2} title="The cost of unequal pay" />

      <CentreColumn>
        <BodyText>
          So you’ve negotiated and you’ve agreed upon a salary with your
          employer. But unbelievably, many companies in the UK are still paying
          women{" "}
          <Link href="https://www.ons.gov.uk/employmentandlabourmarket/peopleinwork/earningsandworkinghours/bulletins/genderpaygapintheuk/2021">
            15% less
          </Link>{" "}
          than their male counterparts for the same work.
        </BodyText>
        <BodyText>
          And it doesn’t stop at salaries. There are gaps across pay rises and
          bonuses, too.
        </BodyText>
        <BodyText>
          Although the same number of men and women received pay rises (43% of
          men and 44% of women), men received an extra £454 (or 32% more than
          women).
        </BodyText>

        <GapChart config={genderPayRise} />

        <BodyText>
          It’s even worse for bonuses. Not only are men more likely to receive a
          bonus than women (25% vs 19%), a man’s average bonus is likely to be
          £526 bigger than a woman’s.
        </BodyText>

        <BodyText>
          The bonus gap has shrunk by £283 since 2019, as everyone’s bonuses
          have got smaller - maybe due to the pandemic. But men’s bonuses are
          still 40% larger than women’s.
        </BodyText>

        <GapChart config={genderBonusGap} />

        <BodyText>
          Equal pay has been part of UK law for over 40 years and was written
          into the Equality Act 2010. So how come we haven’t made more progress?
        </BodyText>
        <BodyText>
          It all comes back to negotiation. Unfortunately, pay rises can be a
          bit like salaries: don’t ask, don’t get.
        </BodyText>
        <BodyText>
          Most of us don’t feel comfortable asking for a pay rise. And guess
          what? Women feel even more uncomfortable than men.
        </BodyText>

        <SquareaChart config={uncomfortablePayRise} />
      </CentreColumn>

      <Sidebar
        sidebar={
          <SidebarStat
            number={35}
            suffix="%"
            text="of women who struggle with asking for a pay rise said it’s due to lack of confidence"
          />
        }
      >
        <BodyText>
          What’s stopping us? Only 4% of people said it’s because{" "}
          <Link href="https://www.totaljobs.com/advice/imposter-phenomenon-in-the-age-of-covid-19">
            they don’t think they deserve one.
          </Link>
        </BodyText>
        <BodyText>
          Interestingly, many of us seem to believe that pay hikes aren’t in the
          company culture. And of course, a global crisis is not the ideal time
          to start this conversation with employers. But for women, one of the
          biggest roadblocks comes from within: confidence.
        </BodyText>

        <ListChart config={payRiseList} />
      </Sidebar>

      <HowTo
        title={"How to gain confidence in asking for a pay rise"}
        description={
          "Gillian Ku, Professor of Organisational Behaviour at London Business School, offers some advice"
        }
        list={[
          <p>
            <strong>Practice, practice, practice.</strong> Rehearse what you’re
            going to say and how you’re going to ask for what you want.
          </p>,
          <p>
            <strong>Prepare some responses</strong> to{" "}
            <Link href="https://hbr.org/2019/11/how-to-deflect-difficult-questions-in-an-interview-or-negotiation">
              deflect tough questions.
            </Link>
          </p>,
          <p>
            <strong>Use confident language,</strong> but be polite and open to
            collaboration. Emphasise your interest in the company, team and
            position.
          </p>,
          <p>
            <strong>Act as though you’re advocating for others.</strong> Think
            about what you’d negotiate for a friend.
          </p>,
          <p>
            <strong>See if someone can advocate for you</strong> &mdash; seek
            advice from decision-makers who can then act on your behalf.
          </p>,
          <p>
            <strong>
              Before negotiating, think about times when you’ve been successful
              and powerful
            </strong>{" "}
            &mdash; this is called power priming. Research shows that this helps
            you in negotiations, and contrary to popular belief holding “power
            poses” doesn’t actually work.
          </p>,
        ]}
      />

      <BoxRectangle />

      <StageTitle stage={3} title="The awareness gap" />

      <CentreColumn>
        <BodyText>
          The results from our survey reveal that gender inequality is still
          very much at work. But just how fluent are we in the language of this
          complex issue?
        </BodyText>
        <BodyText>
          4 in 10 of us have heard of the ‘gender pay gap’. But only 1 in 10 can
          define it.
        </BodyText>
      </CentreColumn>

      <Sidebar
        sidebar={
          <SidebarStat
            number={88}
            suffix="%"
            text="of people got the gender pay gap definition wrong"
          />
        }
      >
        <BodyText>
          Many assume it’s the difference in pay for the same work, but actually
          this is called ‘unequal pay’. And unequal pay is only one aspect of
          the gender pay gap.
        </BodyText>
        <BodyText>
          It's not just less money for the same work. A gender pay gap can have{" "}
          <Link href="https://www.independent.co.uk/life-style/women/gender-pay-gap-equal-pay-women-paid-less-motherhood-a8856121.html">
            many causes
          </Link>
          , including a gender imbalance in senior and higher-earning roles, and
          different working hours.
        </BodyText>
      </Sidebar>

      <Definitions />

      <CentreColumn paddingTop>
        <BodyText>
          Most people seem to (finally) be familiar with ‘equal opportunity’ but
          we are still confused over ‘tokenism’. And unfortunately, only 1 in 20
          people know what intersectionality is.
        </BodyText>
        <BodyText>
          <Link href="https://hbr.org/2011/01/how-women-end-up-on-the-glass-cliff">
            ‘Glass cliff’
          </Link>{" "}
          is a term which almost no one is yet familiar with, including women
          who may find themselves ushered onto it, like former British Prime
          Minister Theresa May famously was.
        </BodyText>
        <BodyText>
          So now we know the right terms, how good are we at spotting equal pay
          in our workplace?
        </BodyText>
        <BodyText>
          Only 4 in 10 women think they work for an organisation that pays
          everyone fairly regardless of gender, age and ethnicity.
        </BodyText>

        <GapChart config={genderFairPay} />

        <BodyText>
          Since the pandemic, it looks like women have upped their opinions of
          the companies they work for. Women are more likely to think their
          employer actively promotes equality and pays everyone fairly now than
          in 2019. Men’s opinions haven’t changed as much.
        </BodyText>

        <GapChart config={genderEquality} />
      </CentreColumn>

      <HalfPipe />

      <BoxTeardrops />

      <StageTitle stage={4} title="Sad resignation" />

      <CentreColumn>
        <BodyText>
          Gender inequality isn’t just a problem for women, it’s a problem for
          their employers too. Feelings of being short-changed plus a
          company-wide lack of equality can be a double-dose of unhappiness for
          female employees. And unhappy employees don’t stick around.
        </BodyText>
        <BodyText>
          For many of us, job (dis)satisfaction goes hand in hand with salary
          (dis)satisfaction. If you feel you’re being paid unfairly, you’re more
          than twice as likely to be unhappy at work.
        </BodyText>

        <MirrorBarChart config={salaryJobSatisfaction} />

        <BodyText>
          An unequal workplace can be a very{" "}
          <Link href="https://www.totaljobs.com/advice/emotions-at-work">
            unhappy workplace
          </Link>
          . People who believe they work for an employer that doesn’t pay
          everyone fairly have even lower job satisfaction, with nearly 5 in 10
          unhappy working there.
        </BodyText>

        <MirrorBarChart config={payInequalityJobSatisfaction} />

        <BodyText>
          And failing to pay everyone equally could cost a company their
          employees. Even in the midst of a pandemic and widespread job
          insecurity, 1 in 2 women would quit their job if they discovered that
          colleagues of the opposite gender earned more for similar work.
        </BodyText>

        <BodyText>
          And it’s not just women that feel this way – 29% of men agreed too.
        </BodyText>

        <SquareaChart config={unequalPayQuit} lessThan50 />

        <BodyText>
          This can often feel like it’s impossible to challenge from the inside.
          So a solution for workers is to move on. But the job hunt holds its
          own dangers...
        </BodyText>
      </CentreColumn>

      <HalfPipe />

      <BoxBoxes />

      <StageTitle stage={5} title="The secret bias of job ads" />

      <CentreColumn>
        <BodyText>
          It’s likely that many women quit because of the gender pay gap.
          They’re searching for equality. But it’s harder to find than you
          think.
        </BodyText>
        <BodyText>
          Why? Because job ads themselves are loaded with hidden gender bias.
        </BodyText>
        <BodyText>
          Gender stereotypes mean we subconsciously label certain words as
          ‘masculine’ or ‘feminine’. You know, words like ‘aggressive’ and
          ‘nurturing’, for example. And when this happens in job ads, it can
          influence who applies for these roles.
        </BodyText>
        <BodyText>
          A{" "}
          <Link href="http://www.fortefoundation.org/site/DocServer/gendered_wording_JPSP.pdf?docID=16121">
            2011 study
          </Link>{" "}
          by Duke University and University of Waterloo found that job ads for
          male-dominated industries had more ‘masculine’ wording than
          female-dominated industries (they didn’t find the same in reverse).
        </BodyText>
        <BodyText>
          It’s a self-perpetuating mechanism that fuels gender inequality. When
          job ads contain more masculine words, people perceive more men within
          these occupations. And crucially, women find them less appealing. So
          fewer women apply, and these industries remain male-dominated.
        </BodyText>
      </CentreColumn>

      <Sidebar
        sidebar={
          <SidebarStat
            title="Science"
            text="the most male-biased job sector for the past five years (2017-2021)"
          />
        }
        endLeft
      >
        <BodyText>
          To see this happening in real life, we{" "}
          <Link href="https://www.totaljobs.com/insidejob/gender-bias-decoder/insights/">
            analysed almost 1 million jobs ads
          </Link>{" "}
          posted from December 2020 - May 2021.
        </BodyText>

        <BodyText>
          ‘Science’ came top of the most male-biased sectors, and the most
          female-biased was ‘Education’. These patterns aren’t shifting. We
          analysed similar data in 2017 to 2019 and and found that Science was
          the most male-dominated industry every year.
        </BodyText>
      </Sidebar>

      <CentreColumn>
        <StackedBarChart config={maleBiasedIndustries} />

        <StackedBarChart config={femaleBiasedIndustries} />

        <BodyText>
          It doesn’t stop at industry either. We found that job ads for senior
          roles were also more male-biased.
        </BodyText>

        <StackedBarChart config={genderBiasedSenior} />

        <BodyText>
          Could this be the glass ceiling? An invisible barrier that prevents
          women from progressing to the top, built from gender-biased language.
        </BodyText>
        <BodyText>
          Consider this: among chief execs of Fortune 500 companies, it’s easier
          to find{" "}
          <Link href="https://www.nytimes.com/interactive/2018/04/24/upshot/women-and-men-named-john.html">
            a man named James than it is to find a woman
          </Link>
          . It’s no wonder that women are put off applying for top roles.
        </BodyText>
        <BodyText>
          The Duke University and University of Waterloo study found that a
          sense of belongingness impacted job appeal.
        </BodyText>
        <BodyText>
          Representation – seeing people similar to yourself – is a crucial part
          of feeling like you belong. Especially when it comes to leadership
          positions. When the highest ranking roles have the most male-biased
          job ads, they’re less likely to appeal to women. So the representation
          gap continues.
        </BodyText>
        <BodyText>
          We don’t have all the answers. But because much of this influence
          exists in the subconscious, it helps to make ourselves more aware of
          it. One way of doing this is{" "}
          <Link href="https://www.totaljobs.com/insidejob/gender-bias-decoder/">
            calling out the hidden bias in our language
          </Link>
          .
        </BodyText>
        <BodyText>
          So, you battle through the maze of gender-biased job ads to find the
          one for you. You apply...
        </BodyText>
      </CentreColumn>

      <HalfPipe final />

      <Congratulations
        final
        onRestart={() => setShowEscapeBar(true)}
        onEscape={onEscape}
      />

      <Conclusion showConclusion={showConclusion} />

      <Footer />
    </Layout>
  )
}

export default IndexPage
