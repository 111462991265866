import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { useOnScreen } from "../../hooks/useOnScreen"
import ChartTitle from "../ChartTitle"
import FadeIn from "../FadeIn"
import StackedBar from "./StackedBar"

const Wrapper = styled.div`
  position: relative;
  padding: 20px 0 80px;
`

const Subtitle = styled.p`
  ${props => props.theme.fontSize(20)}
`

const Key = styled.div`
  ${props => props.theme.fontSize(16)}

  letter-spacing: -0.33px;
  padding: 10px 0 20px;
`

const KeyItem = styled.span`
  position: relative;
  padding: 0 20px;
  white-space: nowrap;
  display: inline-block;
  margin-top: 5px;
`

const KeyIcon = styled.span`
  display: block;
  background-color: ${props => props.theme.colors[props.bgColor]};
  height: 15px;
  width: 15px;
  position: absolute;
  left: 0;
  top: 0;
`

const StackedBarChart = ({ config }) => {
  const { title, subtitle, data } = config
  const [ref, onScreen] = useOnScreen()

  return (
    <FadeIn>
      <Wrapper ref={ref}>
        <ChartTitle>{title}</ChartTitle>
        <Subtitle>{subtitle}</Subtitle>

        <Key>
          <KeyItem>
            <KeyIcon bgColor="men" />
            <span>Male-biased</span>
          </KeyItem>
          <KeyItem>
            <KeyIcon bgColor="women" />
            <span>Female-biased</span>
          </KeyItem>
          <KeyItem>
            <KeyIcon bgColor="pink" />
            <span>Balanced</span>
          </KeyItem>
        </Key>

        {data.map((item, index) => (
          <StackedBar key={index} data={item} onScreen={onScreen} />
        ))}
      </Wrapper>
    </FadeIn>
  )
}

StackedBarChart.propTypes = {
  config: PropTypes.object.isRequired,
}

export default StackedBarChart
