import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { pathClassName } from "../../data/config";

const PIPE_WIDTH = 35;

const Wrapper = styled.div`
    width: ${PIPE_WIDTH}px;
    padding: 0 ${PIPE_WIDTH / 2}px;
    position: absolute;
    top: 0;
    left: 0;
    height: ${props => props.length}px;
    ${props => props.center && `
      left: 50%;
      transform: translateX(-50%);
    `};
`;

const PathDisplay = styled.path`
    stroke: ${props => props.theme.colors.grey};
    stroke-width: ${PIPE_WIDTH}px;
    fill: none;
`;

const SVG = styled.svg`
    overflow: visible;
    z-index: 1;
`;

const Path = ({ height, center, d, noPipe, forceShow, fadeIn }) => {
  if (height === 0) {
    return null;
  }

  return (
    <Wrapper length={height} center={center}>
      <SVG>
        {!noPipe && (
          <PathDisplay
            d={d}
          />
        )}
        <path
          className={pathClassName}
          d={d}
          strokeWidth={0}
          stroke="#000"
          fill="none"
          data-force-show={forceShow}
          data-fade={fadeIn}
        />
      </SVG>
    </Wrapper>
  );
};

Path.propTypes = {
  height: PropTypes.number,
  d: PropTypes.string.isRequired,
  center: PropTypes.bool,
  noPipe: PropTypes.bool,
  forceShow: PropTypes.bool,
  fadeIn: PropTypes.bool
};

Path.defaultProps = {
  height: 0,
  center: false,
  noPipe: false,
  forceShow: false,
  fadeIn: false
};

Path.padding = PIPE_WIDTH / 2;

export default Path;
