import React from "react";
import styled from "styled-components";
import { useOnScreen } from "../../../../hooks/useOnScreen";

const Wrapper = styled.div`
  background-color: #fff;
  height: 25px;
  width: 100%;
  margin-bottom: 20px;
`;

const Bar = styled.div`
  width: ${props => props.visible ? props.value : 0}%;
  height: 100%;
  background-color: ${props => props.theme.colors.pink};
  transition: width ${props => props.theme.animations.duration}ms;
`;

const DefinitionBarChart = ({ value }) => {
  const [ref, onScreen] = useOnScreen();

  return (
    <Wrapper ref={ref}>
      <Bar visible={onScreen} value={value}/>
    </Wrapper>
  );
};

export default DefinitionBarChart;
