import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import FadeIn from "../FadeIn";

const BodyTextStyled = styled.p`
  padding: 0 0 40px;
  margin: 0;
  
  ${props => props.theme.fontSize(20)}
`;

const BodyText = ({ noFade, children }) => (
  <FadeIn disable={noFade}>
    <BodyTextStyled>
      {children}
    </BodyTextStyled>
  </FadeIn>
);

BodyText.propTypes = {
  noFade: PropTypes.bool,
  children: PropTypes.node.isRequired
};

BodyText.defaultProps = {
  noFade: false
};

export default BodyText;
