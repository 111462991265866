import React from "react";
import styled from "styled-components";
import Decode from "./Decode";
import Expect from "./Expect";
import Learn from "./Learn";
import Negotiate from "./Negotiate";

const Wrapper = styled.div`

`;

const HowToTitle = styled.h3`
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.5px;
  padding: 40px 0;
  margin: 0;
`;

const List = styled.ol`
  margin: 0;
  padding: 0 0 50px;
  list-style: none;
`;

const ListItem = styled.li`
  letter-spacing: -0.42px;
  margin-top: 40px;
  
  ${props => props.theme.fontSize(20)}
`;

const ConclusionHowTo = () => (
  <Wrapper>
    <HowToTitle>How to break out of the Gender Pay Trap</HowToTitle>

    <List>
      <ListItem>
        <Learn/>
      </ListItem>
      <ListItem>
        <Expect/>
      </ListItem>
      <ListItem>
        <Negotiate/>
      </ListItem>
      <ListItem>
        <Decode/>
      </ListItem>
    </List>
  </Wrapper>
);

export default ConclusionHowTo;
