import React from "react";
import styled from "styled-components";
import { useOnScreen } from "../../hooks/useOnScreen";
import Path from "../PipeTrack/Path";

const Wrapper = styled.div`
  height: 200px;
  position: relative;
`;

const Rectangle = styled.div`
  width: 200px;
  height: 83px;
  margin: auto;
  background-color: #40B0B0;
  position: relative;
  z-index: 0;
  transform: translate(${props => props.isOnScreen ? -30 : 30}px, 100px);
  transition: transform ${props => props.theme.animations.duration}ms;
`;

const BoxRectangle = () => {
  const [ref, isOnScreen] = useOnScreen(0.6, false);

  return (
    <Wrapper ref={ref}>
      <Rectangle isOnScreen={isOnScreen}/>
      <Path height={200} d={`M0,0 L0,200`} center/>
    </Wrapper>
  );
};

export default BoxRectangle;
