import PropTypes from "prop-types"
import React from "react"
import CountUp from "react-countup"
import styled from "styled-components"

const Area = styled.div`
  position: absolute;
  bottom: 0;
  ${props => props.left && "right: 50%;"}
  ${props => props.right && "left: 50%;"}
  height: 100%;
  width: 50%;
`

const Wrapper = styled.div`
  position: absolute;
  background-color: ${props => props.theme.colors[props.background]};
  bottom: 0;
  ${props => props.left && "right: 0;"}
  ${props => props.right && "left: 0;"}

  width: ${props => props.area}%;
  height: ${props => props.area}%;
  transition: height ${props =>
    props.theme.animations.duration}ms, width ${props =>
  props.theme.animations.duration}ms;
`

const Label = styled.span`
  position: absolute;
  ${props => props.theme.fontSize(20)}
  font-weight: bold;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity ${props => props.theme.animations.duration}ms;

  bottom: 10px;
  text-align: ${props => (props.left ? "right" : "left")};
  ${props => props.left && "right: 10px;"}
  ${props => props.right && "left: 10px;"}

  span {
    display: block;
  }
`

const FULL_AREA = 100 * 100

const Square = ({ data, left, right, onScreen }) => {
  const { value, label, color } = data

  // get the percentage area we need to cover with this square
  const area = FULL_AREA * (value / 100)

  return (
    <Area left={left} right={right}>
      <Wrapper
        background={color}
        left={left}
        right={right}
        area={onScreen ? Math.sqrt(area) : 0}
      >
        <Label left={left} right={right} visible={onScreen}>
          <CountUp
            end={onScreen ? value : 0}
            suffix={"%"}
            separator=","
            decimals={0}
            preserveValue
          />
          <span>{label}</span>
        </Label>
      </Wrapper>
    </Area>
  )
}

Square.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }),
  left: PropTypes.bool,
  right: PropTypes.bool,
}

Square.defaultProps = {
  left: false,
  right: false,
}

export default Square
