import React from "react";
import styled from "styled-components";
import { useOnScreen } from "../../hooks/useOnScreen";

const Wrapper = styled.div`
  padding-left: 120px;
  position: relative;
`;

const SVG = styled.svg`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const Rect = styled.rect`
  transform: translateY(${props => props.translate}px);
  transition: transform 1000ms;
`;

const Learn = () => {
  const [ref, isOnScreen] = useOnScreen(0.5, false);

  return (
    <Wrapper ref={ref}>
      <strong>Learn</strong>
      <p>
        Learn the language of workplace (in)equality. Know how equal pay differs from the gender pay gap. Understand
        what the glass cliff is, so you can avoid it.
      </p>

      <SVG width={92} height={92}>
        <rect fill="#F5F5F5" x="0" y="0" width="92" height="92"/>
        <Rect fill="#40B0B0" x="12" y="46" width="30" height="46" translate={isOnScreen ? -46 : 0}/>
        <Rect fill="#F49164" x="49" y="18" width="30" height="56" translate={isOnScreen ? 18 : 0}/>
      </SVG>
    </Wrapper>
  );
};

export default Learn;
