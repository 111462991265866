import React from "react";
import styled from "styled-components";
import Zigzag from "../../../images/howto/Zigzag.png";

const Wrapper = styled.div`
  width: 100%;
  border: 7px solid #fff;
  margin-bottom: 20px;
  
  ${(props) => props.theme.tablet`
    width: 220px;
    margin: 0;
  `}
  
  ${(props) => props.theme.desktop`
    width: 250px;
  `}
`;

const Title = styled.h4`
  font-size: 24px;
  line-height: 34px;
  letter-spacing: -0.49px;
  font-weight: bold;
  padding: 20px;
  margin: 0;
`;

const BreakLine = styled.div`
  height: 40px;
  border-top: 7px solid #fff;
  border-bottom: 7px solid #fff;
  background: url(${Zigzag}) no-repeat center center;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.33px;
  font-style: italic;
  padding: 20px;
  margin: 0;
`;

const LeftBox = ({ title, description }) => (
  <Wrapper>
    <Title>
      {title}
    </Title>
    <BreakLine/>
    <Description>
      {description}
    </Description>
  </Wrapper>
);

export default LeftBox;
